import classNames from 'classnames';

import s from './index.module.scss';

interface SpecimenItem {
    containsAliquot?: boolean;
    frozen?: boolean;
    currenProcessed?: boolean;
    number: number;
    size?: 'small' | 'large';
}

export function SpecimenItem(props: SpecimenItem) {
    const { containsAliquot, frozen, currenProcessed, number, size = 'large' } = props;
    return (
        <div
            className={classNames(
                s.cell,
                size === 'small' && s.smallCell,
                frozen && s.cellFrozen,
                currenProcessed && s.cellProcessed,
            )}
        >
            <div
                className={classNames(
                    s.specimen,
                    frozen
                        ? containsAliquot
                            ? s.specimenFrozen
                            : s.specimenEmpty
                        : currenProcessed
                        ? containsAliquot
                            ? s.specimenWithAliquot
                            : s.specimenEmpty
                        : null,
                )}
            >
                {(!frozen && !currenProcessed) || containsAliquot ? number : 'E'}
            </div>
        </div>
    );
}
