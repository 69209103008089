import { formatError, RemoteData } from 'aidbox-react';
import notification from 'antd/es/notification';
import { useCallback, useState } from 'react';

import { failure, isFailure, isSuccess, loading, success } from 'aidbox-react/lib/libs/remoteData';
import { service } from 'aidbox-react/lib/services/service';

import { EditableCell } from '../../types';
import { getFlagOptions } from '../utils';
import { stepTwoFlagsOptions } from './flags';

export function useFlagCellStepTwo(props: EditableCell) {
    const { record, reload, sessionStatus, removeRecordError } = props;

    const [validationResultRD, setValidationResultRD] = useState<RemoteData<string>>(success(''));
    const isLoading = validationResultRD === loading;

    const flagOptions = getFlagOptions(stepTwoFlagsOptions);
    const stringsToOptions = useCallback(
        (flagList: EditableCell['record']['flag']) => {
            return flagOptions.filter((option) => flagList.includes(option.value));
        },
        [flagOptions],
    );

    const currentValues = stringsToOptions(record.flag);

    const onFlagChangeStepTwo = async (tags: string[] | null) => {
        setValidationResultRD(loading);

        const patchResponse = await service({
            url: 'LabSessionItem/$update',
            method: 'PATCH',
            data: {
                LabSessionItem: {
                    resourceType: 'LabSessionItem',
                    id: record.id,
                    specimen_id: record.specimen_id,
                    flag: { step_two: tags },
                },
                labSessionStatus: sessionStatus,
            },
        });
        if (isSuccess(patchResponse)) {
            removeRecordError?.(record.id);
            setValidationResultRD(success(''));
        }
        if (isFailure(patchResponse)) {
            setValidationResultRD(failure(formatError(patchResponse.error)));
            notification.error({ message: formatError(patchResponse.error) });
        }
        await reload();
    };

    return {
        onFlagChangeStepTwo,
        isLoading,
        flagOptions,
        currentValues,
    };
}
