import { Card } from 'antd';
import classNames from 'classnames';
import { StatusTag } from 'components/StatusTag';
import { TableSkeletonItem } from 'components/TableSkeletonItem';
import { Boxset } from 'shared/src/contrib/aidbox';
import { formatHumanDateTime } from 'utils/date';

import s from './BoxsetInfo.module.scss';

export function BoxsetInfo(props: { boxset: Boxset }) {
    const { boxset } = props;
    const boxset_total_samples = boxset.sampleRange.end - boxset.sampleRange.start + 1;
    const filed_samples_percentage =
        ((boxset_total_samples - (boxset_total_samples - boxset.processedSamplesCount)) /
            boxset_total_samples) *
        100;
    return (
        <Card className={s.card}>
            <div className={s.boxsetInfo}>
                <div className={s.infoItemWrapper}>
                    <div className={s.infoItem}>
                        <div className={s.infoItemTitle}>Sample Start</div>
                        <div className={s.infoItemData}>{boxset.sampleRange.start}</div>
                    </div>
                </div>
                <div className={s.infoItemWrapper}>
                    <div className={s.infoItem}>
                        <div className={s.infoItemTitle}>Sample End</div>
                        <div className={s.infoItemData}>{boxset.sampleRange.end}</div>
                    </div>
                </div>
                <div className={s.infoItemWrapper}>
                    <div className={s.infoItem}>
                        {' '}
                        <div className={s.infoItemTitle}>Progress</div>
                        <div className={s.infoItemData}>
                            {boxset.processedSamplesCount}/
                            {boxset.sampleRange.end - boxset.sampleRange.start + 1}
                        </div>
                    </div>
                </div>
                <div className={s.infoItemWrapper}>
                    <div className={s.infoItem}>
                        <div className={s.infoItemTitle}>Last Frozen Date/Time</div>
                        <div className={s.infoItemData}>
                            {boxset.lastFrozenDateTime
                                ? formatHumanDateTime(boxset.lastFrozenDateTime)
                                : '-'}
                        </div>
                    </div>
                </div>
                <div className={s.infoItemWrapper}>
                    <div className={s.infoItem}>
                        <div className={s.infoItemTitle}>Status</div>
                        <div className={s.infoItemData}>
                            <StatusTag
                                statusItem={{
                                    value: boxset.status,
                                    filedSamplesPercentage: filed_samples_percentage,
                                    title: `Filled ${boxset.processedSamplesCount} of ${boxset_total_samples} samples`,
                                }}
                                showProgress
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
}

export function BoxsetInfoLoading() {
    return (
        <Card className={classNames(s.card, s.cardLoading)}>
            <div className={s.boxsetInfo}>
                <div className={s.infoItemWrapper}>
                    <div className={s.infoItem}>
                        <div className={s.infoItemTitle}>Sample Start</div>
                        <TableSkeletonItem />
                    </div>
                </div>
                <div className={s.infoItemWrapper}>
                    <div className={s.infoItem}>
                        <div className={s.infoItemTitle}>Sample End</div>
                        <TableSkeletonItem />
                    </div>
                </div>
                <div className={s.infoItemWrapper}>
                    <div className={s.infoItem}>
                        <div className={s.infoItemTitle}>Status</div>
                        <TableSkeletonItem />
                    </div>
                </div>
            </div>
        </Card>
    );
}
