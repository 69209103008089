import { Alert } from 'antd';

export const getDescription = (remoteError: any) => {
    const error = Array.isArray(remoteError) ? remoteError[0] : remoteError;
    const description = error.issue?.[0]?.diagnostics
        ? error.issue[0].diagnostics
        : error?.[0]?.message
        ? error?.[0]?.message
        : typeof error !== 'string'
        ? String(error[0])
        : String(error);
    return description;
};

export const AlertFailure = ({ error }: any | Array<any>) => {
    const description = getDescription(error);
    console.error(JSON.stringify(description));
    return (
        <div style={{ width: '95%' }}>
            <Alert message="Error" description={description} type="error" showIcon />
        </div>
    );
};
