export const stepTwoFlagsOptions = [
    {
        text: 'QNS',
        value: 'qns',
    },
    {
        text: 'Not Available',
        value: 'na',
    },
    {
        text: 'Hemolyzed',
        value: 'hemolyzed',
    },
    {
        text: 'Icteric',
        value: 'icteric',
    },
    {
        text: 'Lipemic',
        value: 'lipemic',
    },
] as const;

export type StepTwoFlag = (typeof stepTwoFlagsOptions)[number]['value'];

export const stepTwoFlagsFilters = [
    ...stepTwoFlagsOptions,
    {
        text: 'Not Set',
        value: 'notSet',
    },
] as const;

export type StepTwoFlagFilter = (typeof stepTwoFlagsFilters)[number]['value'];
