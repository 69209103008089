import { formatError } from 'aidbox-react';
import { notification } from 'antd';
import { InitialDataContext } from 'containers/App/hooks';
import { useContext, useState } from 'react';
import { dateTime, Task } from 'shared/src/contrib/aidbox';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isFailure, isSuccess } from 'aidbox-react/lib/libs/remoteData';
import { mapSuccess, service } from 'aidbox-react/lib/services/service';

export interface OrdersWithTotal {
    orderItems: OrderItem[];
    total: number;
}

export interface OrderItem {
    id: string;
    status: string;
    coordinator_id: string;
    coordinator_display: string;
    mrn: string;
    requester: string;
    order_date_time: dateTime;
    questionnaire_response_id: string | null;
    questionnaire_response_date_time: dateTime | null;
}

export function useLabOrders() {
    const contextData = useContext(InitialDataContext);

    const userData = contextData?.user;
    const userId = userData ? userData['id'] : '';

    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalOrders, setTotalOrders] = useState(0);
    const [filter, setFilter] = useState(true);
    const [order, setOrder] = useState('default');

    const [ordersRd, ordersManager] = useService(async () => {
        const response = await service<OrdersWithTotal>({
            method: 'GET',
            url: `/$orders-list?_count=${pageSize}&_page=${pageNumber}&last_24_hours=${filter}&order_by=${order}`,
        });
        if (isFailure(response)) {
            console.error({ message: JSON.stringify(response.error) });
        }
        return mapSuccess(response, ({ orderItems, total }) => {
            setTotalOrders(total);
            const data: OrdersWithTotal = {
                orderItems,
                total,
            };
            return data;
        });

        return response;
    }, [pageNumber, pageSize, filter, order]);

    const assignOnTask = async (task: string, targetUserId?: string | null) => {
        const user_id = targetUserId === null ? '' : targetUserId || userId;
        const response = await service<Task>({
            method: 'POST',
            url: `/Task/$assign-user?task=${task}&user=${user_id}`,
        });
        if (isSuccess(response)) {
            notification.success({ message: 'User is assigned' });
        }
        if (isFailure(response)) {
            notification.error({ message: formatError(response.error) });
        }

        ordersManager.softReloadAsync();
    };

    return {
        ordersRd,
        manager: ordersManager.softReloadAsync,
        setPageNumber,
        pageNumber,
        setPageSize,
        pageSize,
        totalOrders,
        filter,
        setFilter,
        order,
        setOrder,
        assignOnTask,
    };
}
