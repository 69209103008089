import { TabsProps } from 'antd';
import { useSearchParams } from 'react-router-dom';

export enum SessionEditTarget {
    Aliquoting = 'aliquoting',
    FreezeTubes = 'freeze-tubes',
}

export function useSessionEditMenu() {
    const [searchParams, setSearchParams] = useSearchParams();

    const target = searchParams.get('target');

    const activeKey = target || SessionEditTarget.Aliquoting;

    const onChange = (key: string) => {
        setSearchParams({ target: key });
    };

    const items: TabsProps['items'] = [
        {
            key: SessionEditTarget.Aliquoting,
            label: `Aliquoting`,
        },
        {
            key: SessionEditTarget.FreezeTubes,
            label: `Freeze tubes`,
        },
    ];
    return { activeKey, onChange, items };
}
