import { formatError } from 'aidbox-react';
import { notification } from 'antd';
import { set } from 'lodash';
import { useState } from 'react';

import { isFailure, isSuccess } from 'aidbox-react/lib/libs/remoteData';
import { saveFHIRResource } from 'aidbox-react/lib/services/fhir';

import { User } from '../../contrib/aidbox';
import { getUserRoles, UserRoleValue } from './utils';

export function updateUserRoles(user: User, roles: UserRoleValue[]): User {
    set(user, ['data', 'admin'], undefined);
    set(user, ['data', 'clinicalResearchCoordinator'], undefined);
    set(user, ['data', 'surveyCoordinator'], undefined);
    set(user, ['data', 'labTechnician'], undefined);

    if (!roles.includes('unprivileged')) {
        roles.forEach((role) => {
            set(user, ['data', role], true);
        });
    }

    return user;
}

export function useUserRoleModal(reloadUserList: () => void) {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [currentRoles, setCurrentRoles] = useState<UserRoleValue[]>([]);

    const onRoleChange = (values: UserRoleValue[]) => {
        if (values.includes('unprivileged') && !currentRoles.includes('unprivileged')) {
            setCurrentRoles(['unprivileged']);
            return;
        }
        if (values.includes('unprivileged') && currentRoles.includes('unprivileged')) {
            const newRoles = values.filter((role: UserRoleValue) => role !== 'unprivileged');
            setCurrentRoles(newRoles);
            return;
        }
        setCurrentRoles(values);
    };

    function showUserRoleModal(user: User) {
        setCurrentUser(user);
        setCurrentRoles(getUserRoles(user));
        setModalOpen(true);
    }

    function closeModal() {
        setCurrentUser(null);
        setCurrentRoles([]);
        setModalOpen(false);
    }

    async function saveUserRoles() {
        if (!currentUser) {
            console.error('No current user');
            return;
        }
        if (!currentRoles) {
            console.error('No current role');
            return;
        }
        const updatedUser = updateUserRoles(currentUser, currentRoles);
        const response = await saveFHIRResource(updatedUser);
        if (isSuccess(response)) {
            notification.success({
                message: 'User roles updated',
            });
            closeModal();
            reloadUserList();
        }
        if (isFailure(response)) {
            notification.error({ message: formatError(response.error) });
            closeModal();
            reloadUserList();
        }
    }

    return {
        modalOpen,
        showUserRoleModal,
        closeModal,
        currentUser,
        saveUserRoles,
        currentRoles,
        onRoleChange,
    };
}
