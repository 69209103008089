import { CheckOutlined } from '@ant-design/icons';
import { useState } from 'react';

import s from './IdentifierCell.module.scss';

export function IdentifierCell(props: { text: string }) {
    const { text } = props;
    const [copied, setCopied] = useState(false);
    const onClick = () => {
        navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
    };

    return (
        <div onClick={onClick} className={s.identCell}>
            <span>{text}</span>
            <div className={s.copiedIconWrapper}>{copied ? <CheckOutlined /> : null}</div>
        </div>
    );
}
