import { formatError } from 'aidbox-react';
import { Form, notification } from 'antd';
import { useContext, useState } from 'react';
import { LabSession } from 'shared/src/contrib/aidbox';

import { isSuccess } from 'aidbox-react/lib/libs/remoteData';

import { saveAppResource } from '../../../services/immunix';
import { getCurrentFHIRDate } from '../../../utils/date';
import { InitialDataContext } from '../../App/hooks';

export interface ReasonFormValues {
    reason: string;
}

export interface LabSessionEditControlProps {
    labSession: LabSession;
    viewOnly: boolean;
    setViewOnly: (viewOnly: boolean) => void;
}

export function useLabSessionEditControl({
    labSession,
    viewOnly,
    setViewOnly,
}: LabSessionEditControlProps) {
    const contextData = useContext(InitialDataContext);
    const userId = contextData?.user?.id;

    const [showModal, setShowModal] = useState(false);

    const [reasonText, setReasonText] = useState<string | undefined>(undefined);

    function handleEditModeToggle() {
        if (viewOnly) {
            setShowModal(true);
        } else {
            setViewOnly(true);
        }
    }

    const [captureReasonForm] = Form.useForm();

    async function saveReason(values: ReasonFormValues) {
        const logEntry = {
            resourceType: 'LogEntry',
            author: {
                id: userId,
                resourceType: 'User',
            },
            dateTime: getCurrentFHIRDate(),
            action: 'lab-session-edit',
            target: {
                id: labSession.id,
                resourceType: 'LabSession',
            },
            text: values.reason,
        };
        const response = await saveAppResource(logEntry);
        if (isSuccess(response)) {
            startEditing(values.reason);
            captureReasonForm.resetFields();
        } else {
            notification.warning({ message: formatError(response.error) });
        }
    }

    function startEditing(reason: string) {
        setReasonText(reason);
        setShowModal(false);
        setViewOnly(false);
    }

    return {
        handleEditModeToggle,
        saveReason,
        captureReasonForm,
        reasonText,
        showModal,
        setShowModal,
    };
}
