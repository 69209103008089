import { Button, Card, Divider } from 'antd';
import { AppHeader } from 'components/BaseLayout/AppHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    AUTHORIZATION_CODE_CLIENT_ID,
    generateCodeChallenge,
    generateRandomString,
} from 'services/auth';

import s from './LoginIn.module.scss';
export function LoginIn() {
    const navigate = useNavigate();

    const baseURL = (window as any).__SPACONFIG__.baseURL;
    const identityProviders = (window as any).__SPACONFIG__.identityProviders || '[]';

    const identityProvidersList: Array<{ id: string; title: string }> =
        JSON.parse(identityProviders);

    const codeVerifier = generateRandomString(70);
    sessionStorage.setItem('code_verifier', codeVerifier);

    const location = useLocation();
    const entryPoint = location.state?.entryPoint;
    if (entryPoint) {
        localStorage.setItem('entry_point', entryPoint);
    } else {
        localStorage.removeItem('entry_point');
    }

    const handleClick = async (ssoId: string) => {
        const codeChallenge = await generateCodeChallenge(codeVerifier);
        const ssoURL = `${baseURL}/auth/redirect/${ssoId}?response_type=code&client_id=${AUTHORIZATION_CODE_CLIENT_ID}&state=${codeVerifier}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
        window.location.href = ssoURL;
    };
    return (
        <div className={s.wrapper}>
            <AppHeader />
            <div className={s.content}>
                <Card className={s.card}>
                    <img src="./logo.svg" alt="logo" className={s.logo} />
                    <div className={s.ssoButtons}>
                        {identityProvidersList.map(({ id, title }) => {
                            return (
                                <Button
                                    type="primary"
                                    onClick={() => handleClick(id)}
                                    size="large"
                                    key={id}
                                >
                                    Login with {title}
                                </Button>
                            );
                        })}
                    </div>
                    {identityProvidersList.length > 0 && <Divider>Or</Divider>}
                    <Button
                        type="link"
                        onClick={() => {
                            navigate('/signin');
                        }}
                    >
                        Admin login
                    </Button>
                </Card>
            </div>
        </div>
    );
}
