import classNames from 'classnames';
import { ReactNode } from 'react';

import { AppHeader } from './AppHeader';
import { AppMenu } from './AppMenu';
import s from './BaseLayout.module.scss';

interface Props {
    children?: ReactNode | ReactNode[];
    showHeader?: boolean;
    showMainMenu?: boolean;
    className?: string;
}

export function BaseLayout(props: Props) {
    const { showHeader = false, showMainMenu = false, className } = props;

    return (
        <div className={s.baseWrapper}>
            {showHeader && <AppHeader />}
            {showMainMenu && <AppMenu />}
            <div className={classNames(s.contentWrapper, className)}>{props.children}</div>
        </div>
    );
}
