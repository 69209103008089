import { RenderRemoteData } from 'aidbox-react';
import { Breadcrumb, Divider } from 'antd';
import { AlertFailure } from 'components/AlertFailure';
import { SessionSteps } from 'components/SessionSteps';
import { useNavigate } from 'react-router-dom';
import { formatHumanDateTime } from 'utils/date';

import { BaseLayout } from '../../components/BaseLayout';
import { SessionEditMenu } from '../../components/SessionEditMenu';
import { useSessionPage } from './hooks';
import { LabSessionEditRouter } from './LabSessionEditRouter';
import s from './LabSessionPage.module.scss';
import { TableStepOneContainer } from './TableStepOne';
import { TableStepThreeContainer } from './TableStepThree';
import { TableStepTwoContainer } from './TableStepTwo';
import { LabSessionProps } from './types';

export function LabSessionPage() {
    const { labSessionRD, sessionReload } = useSessionPage();
    const navigate = useNavigate();

    return (
        <BaseLayout showHeader={true} showMainMenu={true}>
            <RenderRemoteData
                remoteData={labSessionRD}
                renderFailure={(error) => <AlertFailure error={error} />}
            >
                {(labSession) => {
                    return (
                        <>
                            <Breadcrumb className={s.breadcrumb}>
                                <Breadcrumb.Item
                                    onClick={() => {
                                        navigate(-1);
                                    }}
                                >
                                    <a>Sessions</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item className={s.activeBreadcrumb}>
                                    Session at {formatHumanDateTime(labSession.createdDateTime)}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                            <div className={s.header}>
                                <h2 className={s.pageTitle}>Specimens</h2>
                                {labSession.status === 'completed' ? (
                                    <SessionEditMenu />
                                ) : (
                                    <SessionSteps status={labSession.status} />
                                )}
                            </div>
                            <Divider className={s.divider} />
                            <StepTables labSession={labSession} sessionReload={sessionReload} />
                        </>
                    );
                }}
            </RenderRemoteData>
        </BaseLayout>
    );
}

function StepTables(props: LabSessionProps) {
    switch (props.labSession.status) {
        case 'collect-and-evaluate':
            return <TableStepOneContainer {...props} />;
        case 'aliquoting':
            return <TableStepTwoContainer {...props} />;
        case 'freeze-tubes':
            return <TableStepThreeContainer {...props} />;
        case 'completed':
            return <LabSessionEditRouter {...props} />;
        default:
            return <p>Not handled step</p>;
    }
}
