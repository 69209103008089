import Select from 'antd/es/select';

import s from './AliquotsSelect.module.scss';
import { useAliquotsSelect } from './hooks';
import { AliquotsSelectProps } from './types';

export function AliquotsSelect(props: AliquotsSelectProps) {
    const { value, options, onAliquotChange, isLoading } = useAliquotsSelect(props);
    return (
        <Select
            options={options}
            value={value}
            loading={isLoading}
            className={s.antSelect}
            onChange={onAliquotChange}
            disabled={props.viewOnly || isLoading}
        />
    );
}
