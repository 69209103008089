import React from 'react';

export interface SetActions<T> {
    add: (item: T) => void;
    set: (items: T[]) => void;
    remove: (item: T) => void;
    clear: () => void;
}

type UseSet<T> = [Set<T>, SetActions<T>];

export function useSet<T>(initialValue?: Set<T>): UseSet<T> {
    const [set, setSet] = React.useState<Set<T>>(new Set(initialValue));

    const actions = React.useMemo(
        () => ({
            add: (item: T) => setSet((prevSet) => new Set([...prevSet, item])),
            set: (items: T[]) => setSet(new Set(items)),
            remove: (item: T) =>
                setSet((prevSet) => new Set([...prevSet].filter((i) => i !== item))),
            clear: () => setSet(new Set()),
        }),
        [setSet],
    );

    return [set, actions];
}
