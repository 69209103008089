import { formatDistanceToNowStrict } from 'date-fns';
import { LabSession } from 'shared/src/contrib/aidbox';
import { formatHumanDateTime } from 'utils/date';

import { service } from 'aidbox-react/lib/services/service';

import { LabSessionTableItem } from './hooks';

export async function createLabSession() {
    return service<LabSession>({
        method: 'POST',
        url: '/LabSession/$create',
    });
}

export function formatLabSessionsList(sessionItems: LabSessionTableItem[]): LabSessionTableItem[] {
    return sessionItems.map((item) => {
        const dateDistance = formatDistanceToNowStrict(new Date(item.created_date), {
            addSuffix: true,
        });
        return {
            id: item.id,
            created_date: `${formatHumanDateTime(item.created_date)} - ${dateDistance}`,
            status: item.status,
            specimens_count: item.specimens_count,
            min_collected_date: item.min_collected_date,
            max_collected_date: item.max_collected_date,
        };
    });
}

const labStatusValues: { [key: string]: { color: string } } = {
    active: { color: 'var(--orange)' },
    cancelled: {
        color: 'var(--dark-pink)',
    },
    completed: { color: 'var(--light-teal)' },
};

type displayLabSessionStatus = 'active' | Partial<LabSession['status']>;

export function getLabSessionTag(status: LabSession['status']) {
    let displayStatus: displayLabSessionStatus = status;
    switch (status) {
        case 'collect-and-evaluate':
        case 'aliquoting':
        case 'freeze-tubes':
            displayStatus = 'active';
            break;
        default:
            displayStatus = status;
            break;
    }
    const statusData = labStatusValues[displayStatus];

    return { name: displayStatus, ...statusData };
}

const stepValues: { [key: string]: { color: string } } = {
    'collect-and-evaluate': { color: 'var(--orange)' },
    aliquoting: {
        color: 'var(--peach)',
    },
    'freeze-tubes': { color: 'var(--blue)' },
};
export function getLabSessionStep(status: activeLabSessionStatus) {
    const statusData = stepValues[status];
    return { name: statusStepMap[status], ...statusData };
}

type activeLabSessionStatus = 'collect-and-evaluate' | 'aliquoting' | 'freeze-tubes';

export const statusStepMap: Record<activeLabSessionStatus, string> = {
    'collect-and-evaluate': 'collecting',
    aliquoting: 'aliquoting',
    'freeze-tubes': 'freeze tubes',
};
