import { RemoteDataResult, ServiceManager, WithId } from 'aidbox-react';
import { TagData } from 'components/StatusTag';
import { ApplicationConfig } from 'sharedState';

import { service } from 'aidbox-react/lib/services/service';

import { BoxsetTableItem } from '.';
import { Boxset } from '../../contrib/aidbox';
import { BoxsetListWithTotal } from './hooks';

export function formatBoxSetsList(boxSets: WithId<Boxset>[]): BoxsetTableItem[] {
    return boxSets.map((boxset) => {
        const totalSamples = boxset.sampleRange.end - boxset.sampleRange.start + 1;
        const emptySamples = totalSamples - boxset.processedSamplesCount;
        return {
            boxsetNumber: boxset.boxsetNumber,
            boxsetFilled: !!boxset.filled,
            id: boxset.id,
            sampleStart: boxset.sampleRange.start,
            sampleEnd: boxset.sampleRange.end,
            status: {
                value: boxset.status,
                filedSamplesPercentage: ((totalSamples - emptySamples) / totalSamples) * 100,
                title: `Filled ${totalSamples - emptySamples} of ${totalSamples} samples`,
            },
        };
    });
}

export function getTagData(status: string, applicationConfig: ApplicationConfig) {
    const { humanReadableBoxsetStatuses } = applicationConfig;
    const statusTagData: Partial<Record<Boxset['status'], TagData>> = {};

    Object.entries(humanReadableBoxsetStatuses).map(([boxsetStatus, name]) => {
        statusTagData[boxsetStatus] = { name, color: statusColorValues[boxsetStatus].color };
    });

    return statusTagData[status] || { name: status };
}

const statusColorValues: { [key in Boxset['status']]: { color: string } } = {
    initial: { color: 'var(--teal-green)' },
    'ready-to-be-sent-to-hospital': {
        color: 'var(--violet)',
    },
    'sent-to-hospital': { color: 'var(--deep-pink)' },
    'in-hospital': { color: 'var(--blue)' },
    'sent-to-prenosis': { color: 'var(--coral)' },
    'arrived-to-prenosis': { color: 'var(--teal)' },
};

export async function createBoxset() {
    return service<Boxset>({
        method: 'POST',
        url: '/Boxset/$create',
    });
}

export function getButtonTitle(status: Boxset['status']) {
    return humanReadableButtonTitles[status];
}

const humanReadableButtonTitles: Partial<Record<Boxset['status'], string>> = {
    initial: 'Ready to be sent',
    'ready-to-be-sent-to-hospital': 'Send to Hospital',
    'sent-to-hospital': 'Confirm receipt',
    'sent-to-prenosis': 'Confirm receipt',
    'in-hospital': 'Send to Prenosis',
};

export function getModalTitle(status: Boxset['status'], boxsetNumber: number) {
    switch (status) {
        case 'ready-to-be-sent-to-hospital':
            return `Do you really want mark Boxset ${boxsetNumber} as  'Ready to be sent' ?`;
        case 'sent-to-hospital':
            return `Do you really want send Boxset ${boxsetNumber} to the hospital ?`;
        case 'in-hospital':
            return `Do you really want to confirm receipt of the Boxset ${boxsetNumber} to the hospital ?`;
        case 'sent-to-prenosis':
            return `Do you really want send Boxset ${boxsetNumber} to Prenosis ?`;
        case 'arrived-to-prenosis':
            return `Do you really want to confirm receipt of the Boxset ${boxsetNumber} ?`;
        default:
            return `Do you really want to change status for Boxset ${boxsetNumber} to ${status} ? `;
    }
}

export function getFilters(applicationConfig: ApplicationConfig) {
    const boxsetStatuses = applicationConfig.boxsetStatuses;
    const humanReadableBoxsetStatuses = applicationConfig.humanReadableBoxsetStatuses;
    return boxsetStatuses.map((status) => {
        return {
            text: humanReadableBoxsetStatuses[status],
            value: status,
        };
    });
}

export function calculateFilteredStatuses(
    userStatuses: ApplicationConfig['boxsetStatuses'],
    filterStatuses: (boolean | React.Key)[] | null,
) {
    if (!filterStatuses) {
        return userStatuses.toString();
    } else {
        return filterStatuses.toString();
    }
}

export const mockedServiceManager: ServiceManager<
    BoxsetListWithTotal,
    any
>['softReloadAsync'] = () => {
    return Promise.resolve({} as RemoteDataResult<BoxsetListWithTotal, any>);
};

export const mockUserConfig: ApplicationConfig = {
    transitionMapStatuses: {
        initial: [],
        'ready-to-be-sent-to-hospital': [],
        'sent-to-hospital': ['in-hospital'],
        'in-hospital': ['sent-to-prenosis'],
        'sent-to-prenosis': [],
    },
    boxsetStatuses: ['sent-to-hospital', 'in-hospital', 'sent-to-prenosis', 'arrived-to-prenosis'],
    humanReadableBoxsetStatuses: {
        initial: 'New',
        'ready-to-be-sent-to-hospital': 'Ready to be sent',
        'sent-to-hospital': 'Sent to hospital',
        'in-hospital': 'At Clinical Site',
        'sent-to-prenosis': 'Sent to Prenosis',
        'arrived-to-prenosis': 'Arrived to Prenosis',
    },
    specimenIdColumnName: 'Specimen ID',
};
