import { Tag } from 'antd';
import Select, { components, SingleValueProps } from 'react-select';

import { EditableCell } from '../../types';
import s from '../FlagsCell.module.scss';
import { CellOption as Option } from '../index';
import { FlagOption, RawFlagOptionsStepOne } from '../types';
import { getFlagColorConfig, getFlagOptions } from '../utils';
import { stepOneFlagsOptions } from './flags';
import { useFlagCellStepOne } from './hooks';

export function FlagsCellStepOne(props: EditableCell) {
    const { record } = props;
    const currentTag = record.flag;
    const flagOptions = getFlagOptions(stepOneFlagsOptions);
    const currentValue = flagOptions.find((option) => option.value === currentTag);
    const { onFlagChangeStepOne, isLoading } = useFlagCellStepOne(props);

    return (
        <Select
            options={flagOptions}
            className={s.flagSelect}
            isSearchable={false}
            isLoading={isLoading}
            defaultValue={currentValue}
            isMulti={false}
            menuPlacement="auto"
            classNamePrefix="react-select"
            onChange={(newValue) => {
                onFlagChangeStepOne(newValue?.value || null);
            }}
            components={{
                IndicatorSeparator: () => null,
                SingleValue: ValueOptionLabel,
                Option,
            }}
        />
    );
}

const ValueOptionLabel = (props: SingleValueProps<FlagOption<RawFlagOptionsStepOne>>) => {
    const { ValueContainer } = components;
    const option = props.data;
    const tagData = getFlagColorConfig(option.value);
    return (
        <ValueContainer {...props}>
            <Tag color={tagData.color} title={tagData.title} className={s.tag}>
                <div>{option.label}</div>
                <div className={s.removeBtn} onClick={props.clearValue}>
                    &times;
                </div>
            </Tag>
        </ValueContainer>
    );
};
