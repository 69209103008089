import { useParams } from 'react-router-dom';
import { getAppResources } from 'services/immunix';
import { Boxset, LogEntry, User } from 'shared/src/contrib/aidbox';

import { useService } from 'aidbox-react/lib/hooks/service';
import { extractBundleResources } from 'aidbox-react/lib/services/fhir';
import { mapSuccess } from 'aidbox-react/lib/services/service';

import { modifyLogEntries } from './utils';

export function useBoxsetDetails() {
    const { boxsetNumber } = useParams();
    const [boxsetRD, boxsetManager] = useService(async () => {
        return mapSuccess(
            await getAppResources<Boxset | LogEntry | User>('Boxset', {
                boxsetNumber: boxsetNumber,
                _revinclude: 'LogEntry:target',
                _include: 'LogEntry:author:User',
                _elements: 'User.data',
            }),
            (bundle) => {
                const resourcesMap = extractBundleResources(bundle);
                const logEntries = modifyLogEntries(resourcesMap.LogEntry, resourcesMap.User);
                return {
                    boxset: resourcesMap.Boxset[0],
                    logEntries,
                };
            },
        );
    });

    return { boxsetRD, boxsetManager };
}
