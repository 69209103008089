import { QuestionItemProps } from '@beda.software/fhir-questionnaire';
import { Col, Form, InputNumber, Row, Slider } from 'antd';
import { Coding } from 'shared/src/contrib/aidbox';

import { useFieldController } from '../hooks';

const inputStyle = { width: '100%' };

interface NumericItem {
    unit?: Coding;
}

export function QuestionInteger({ parentPath, questionItem }: QuestionItemProps) {
    const { linkId, required } = questionItem;
    const { unit } = questionItem as NumericItem;
    const fieldName = [...parentPath, linkId, 0, 'value', 'integer'];
    const { value, onChange, disabled, formItem } = useFieldController(fieldName, questionItem);

    return (
        <Form.Item {...formItem}>
            <InputNumber
                addonAfter={unit?.display}
                style={inputStyle}
                disabled={disabled}
                onChange={onChange}
                value={value}
                required={required}
            />
        </Form.Item>
    );
}

export function QuestionDecimal({ parentPath, questionItem }: QuestionItemProps) {
    const { linkId } = questionItem;
    const { unit } = questionItem as NumericItem;
    const fieldName = [...parentPath, linkId, 0, 'value', 'decimal'];
    const { value, onChange, disabled, formItem } = useFieldController(fieldName, questionItem);

    return (
        <Form.Item {...formItem}>
            <InputNumber
                addonAfter={unit?.display}
                style={inputStyle}
                disabled={disabled}
                onChange={onChange}
                value={value}
            />
        </Form.Item>
    );
}

export function QuestionPercentage({ parentPath, questionItem }: QuestionItemProps) {
    const { linkId } = questionItem;
    const fieldName = [...parentPath, linkId, 0, 'value', 'decimal'];
    const { value, onChange, disabled, formItem } = useFieldController(fieldName, questionItem);

    return (
        <Form.Item {...formItem}>
            <Row gutter={40}>
                <Col span={6}>
                    <InputNumber
                        addonAfter="%"
                        min={0}
                        max={100}
                        style={inputStyle}
                        disabled={disabled}
                        onChange={onChange}
                        value={value}
                    />
                </Col>
                <Col span={12}>
                    <Slider
                        min={0}
                        max={100}
                        disabled={disabled}
                        onChange={onChange}
                        value={typeof value === 'number' ? value : 0}
                    />
                </Col>
            </Row>
        </Form.Item>
    );
}
