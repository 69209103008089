import { useEffect } from 'react';

import { generateRandomString, oAuth2AuthCodeAuthorize } from '../../services/auth';

export function SignIn() {
    const codeVerifier = generateRandomString(70);
    sessionStorage.setItem('code_verifier', codeVerifier);

    useEffect(() => {
        oAuth2AuthCodeAuthorize({ codeVerifier, state: '' });
    });

    return <></>;
}
