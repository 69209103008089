import classNames from 'classnames';
import { BoxsetMapLegend } from 'components/BoxsetMapLegend';
import { SpecimenItem } from 'components/SpecimenItem';
import { LabSampleItem } from 'shared/src/contrib/aidbox';

import { RenderRemoteData, WithId } from 'aidbox-react/lib';

import s from './BoxsetMap.module.scss';
import { BoxItemResources, useBoxsetMapData } from './hooks';

export function BoxsetMap(props: {
    boxsetId: string;
    activeRange?: {
        start: number;
        end: number;
    };
}) {
    const { boxsetId, activeRange } = props;

    const { boxsetRD } = useBoxsetMapData(boxsetId);

    return (
        <>
            <RenderRemoteData remoteData={boxsetRD}>
                {({ boxset, boxItemResourcesMap }) => {
                    const boxItemsResources = Object.values(boxItemResourcesMap);
                    return (
                        <div className={s.wrapper}>
                            <div className={s.header}>
                                <span
                                    className={s.name}
                                >{`Boxset ${boxset.boxsetNumber} Map`}</span>
                                <BoxsetMapLegend />
                            </div>
                            <div className={s.samplesRange}>
                                <span>
                                    Sample start:{' '}
                                    <span className={s.sampleNumber}>
                                        {boxset.sampleRange.start}
                                    </span>
                                </span>
                                <span>
                                    Sample end:{' '}
                                    <span className={s.sampleNumber}>{boxset.sampleRange.end}</span>
                                </span>
                            </div>
                            <div className={s.boxsetMap}>
                                {boxItemsResources.map((boxItemResources) => (
                                    <BoxItem
                                        boxItemResources={boxItemResources}
                                        activeRange={activeRange}
                                        key={boxItemResources.box.id}
                                    ></BoxItem>
                                ))}
                            </div>
                        </div>
                    );
                }}
            </RenderRemoteData>
        </>
    );
}

function BoxItem(props: {
    boxItemResources: BoxItemResources;
    activeRange?: { start: number; end: number };
}) {
    const { boxItemResources, activeRange } = props;
    const { box, boxType, labSampleItems } = boxItemResources;
    return (
        <div className={s.box} key={box.id}>
            <span className={s.boxTitle}>Box {boxType.name}</span>
            <div
                className={classNames(
                    s.samplesWrapper,
                    boxType.tubeType === 'PCR' ? s.screwCapWrapper : null,
                )}
            >
                {labSampleItems.map((labSampleItem) => (
                    <SampleTubes
                        labSampleItem={labSampleItem}
                        boxType={boxType}
                        activeRange={activeRange}
                        key={labSampleItem.id}
                    />
                ))}
            </div>
        </div>
    );
}

function SampleTubes(props: {
    labSampleItem: WithId<LabSampleItem>;
    boxType: BoxItemResources['boxType'];
    activeRange?: { start: number; end: number };
}) {
    const { labSampleItem, boxType, activeRange } = props;
    const { sampleNumber, frozenDateTime, filledTubesCount } = labSampleItem;
    const currenProcessed =
        activeRange && sampleNumber >= activeRange.start && sampleNumber <= activeRange.end
            ? true
            : false;
    const sampleTubes = Array.from({ length: boxType.tubesPerSample }, (_, i) => i + 1);

    return (
        <div className={s.samplesGroup}>
            {sampleTubes.map((tube) => {
                return (
                    <SpecimenItem
                        number={sampleNumber}
                        frozen={!!frozenDateTime}
                        containsAliquot={filledTubesCount ? filledTubesCount >= tube : false}
                        size={boxType.tubeType === 'PCR' ? 'small' : 'large'}
                        currenProcessed={currenProcessed}
                        key={tube}
                    ></SpecimenItem>
                );
            })}
        </div>
    );
}
