import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { RenderRemoteData } from '@beda.software/fhir-react';
import { Button, List, Modal } from 'antd';
import { useState } from 'react';

import { useService } from 'aidbox-react/lib/hooks/service';
import { extractBundleResources, getFHIRResources } from 'aidbox-react/lib/services/fhir';
import { mapSuccess } from 'aidbox-react/lib/services/service';

import { User } from '../../../packages/@beda.software/fhir-questionnaire/contrib/aidbox';
import { getUserDisplay } from '../../../utils/getUserDisplay';
import s from '../../Boxsets/ChangeStatusModal/ChangeStatusModal.module.scss';

interface Props {
    currentAssignedUserId: string;
    onSuccess: (userId: string | null) => void;
}

export function SurveyAssignControl(props: Props) {
    const [isOpen, setOpenModal] = useState<boolean>(false);

    function closeModal() {
        setOpenModal(false);
    }

    return (
        <>
            {props.currentAssignedUserId ? (
                <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => {
                        setOpenModal(true);
                    }}
                />
            ) : (
                <Button
                    style={{ paddingLeft: 0 }}
                    type="link"
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setOpenModal(true);
                    }}
                >
                    Assign
                </Button>
            )}

            {isOpen && <SurveyAssigneeModal {...props} closeModal={closeModal} />}
        </>
    );
}

interface SurveyAssigneeModalProps extends Props {
    closeModal: () => void;
}

function SurveyAssigneeModal({
    currentAssignedUserId,
    onSuccess,
    closeModal,
}: SurveyAssigneeModalProps) {
    const [coordinatorListRD] = useService(async () => {
        const response = await getFHIRResources<User>('User', {
            _ilike: 'survey-coordinator',
            _elements: 'id,email,data',
        });
        return mapSuccess(response, (bundle) => extractBundleResources(bundle).User);
    });

    return (
        <Modal
            open={true}
            closable={false}
            title="Assign a user to the survey collection task"
            footer={[
                <Button key="CancelBtn" onClick={closeModal}>
                    Cancel
                </Button>,
            ]}
        >
            <div className={s.modal}>
                <RenderRemoteData remoteData={coordinatorListRD}>
                    {(coordinatorList) => (
                        <List
                            itemLayout="horizontal"
                            dataSource={coordinatorList}
                            renderItem={(user) => (
                                <List.Item key={user.id}>
                                    <Button
                                        type="link"
                                        onClick={() => {
                                            onSuccess(user.id);
                                            closeModal();
                                        }}
                                    >
                                        {getUserDisplay(user)}
                                    </Button>
                                    {currentAssignedUserId === user.id && <div>Current</div>}
                                </List.Item>
                            )}
                        >
                            <List.Item>
                                {' '}
                                <Button
                                    type="link"
                                    onClick={() => {
                                        onSuccess(null);
                                        closeModal();
                                    }}
                                >
                                    Unassign
                                </Button>
                            </List.Item>
                        </List>
                    )}
                </RenderRemoteData>
            </div>
        </Modal>
    );
}
