import { Tag } from 'antd';
import classNames from 'classnames';
import Select, { components, MultiValueProps } from 'react-select';

import { EditableCell } from '../../types';
import s from '../FlagsCell.module.scss';
import { CellOption } from '../index';
import { FlagOption, RawFlagOptionsStepTwo } from '../types';
import { getFlagColorConfig } from '../utils';
import { useFlagCellStepTwo } from './hooks';

export function FlagsCellStepTwo(props: EditableCell) {
    const { viewOnly } = props;
    const { isLoading, onFlagChangeStepTwo, currentValues, flagOptions } =
        useFlagCellStepTwo(props);

    return (
        <Select
            options={flagOptions}
            className={classNames(s.flagSelect, isLoading && s.selectLoading)}
            isSearchable={false}
            isClearable={false}
            isDisabled={viewOnly ? true : isLoading}
            value={currentValues}
            menuPlacement="auto"
            isMulti={true}
            classNamePrefix="react-select"
            onChange={(values) => {
                const newTags = values.map((option) => option.value);
                onFlagChangeStepTwo(newTags.length ? newTags : null);
            }}
            components={{
                IndicatorSeparator: () => null,
                MultiValue,
                Option: CellOption,
            }}
        />
    );
}

const MultiValue = (props: MultiValueProps<FlagOption<RawFlagOptionsStepTwo>>) => {
    const { MultiValueContainer } = components;
    const option = props.data;
    const tagData = getFlagColorConfig(option.value);
    const { Remove } = props.components;

    return (
        <MultiValueContainer {...props}>
            <Tag
                color={tagData.color}
                title={tagData.title}
                style={{ color: tagData.textColor }}
                closable
                className={s.tag}
                closeIcon={
                    <Remove
                        data={props.data}
                        innerProps={{
                            ...props.removeProps,
                            title: 'remove',
                            style: { color: tagData.textColor },
                            className: s.removeBtn,
                        }}
                        selectProps={props.selectProps}
                        children={<>&times;</>}
                    />
                }
            >
                {option.label}
            </Tag>
        </MultiValueContainer>
    );
};
