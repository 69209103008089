import { User } from '../../contrib/aidbox';

type UserRolesMap = {
    [key in UserRoleValue]: { name: string; description: string[] };
};

export const userRolesMap: UserRolesMap = {
    admin: {
        name: 'Administrator',
        description: ['manage user permissions', 'manage box sets', 'manage sessions'],
    },
    clinicalResearchCoordinator: {
        name: 'Clinical Research Coordinator',
        description: ['view box sets and sessions'],
    },
    surveyCoordinator: {
        name: 'Survey Coordinator',
        description: ['get access to surveys list and forms'],
    },
    labTechnician: {
        name: 'Laboratory Technician',
        description: ['accept and send box sets', 'manage sessions'],
    },
    unprivileged: {
        name: 'Unprivileged',
        description: ['no permissions'],
    },
};

export type UserRoleValue =
    | 'admin'
    | 'clinicalResearchCoordinator'
    | 'surveyCoordinator'
    | 'labTechnician'
    | 'unprivileged';

export function getUserRoles(user: User): UserRoleValue[] {
    const roles: UserRoleValue[] = [];
    const allUserRoles = Object.keys(userRolesMap) as UserRoleValue[];
    if (user.id === 'admin') {
        roles.push('admin');
    }
    allUserRoles.forEach((role) => {
        if (user.data?.[role]) {
            roles.push(role);
        }
    });
    if (roles.length === 0) {
        roles.push('unprivileged');
    }
    return roles;
}
