import { QuestionItemProps } from '@beda.software/fhir-questionnaire';
import { Form } from 'antd';
import { getDisplay } from 'components/readonly-widgets/choice';
import _ from 'lodash';
import { useCallback } from 'react';
import Select from 'react-select';
import {
    QuestionnaireItemAnswerOption,
    QuestionnaireResponseItemAnswer,
} from 'shared/src/contrib/aidbox';

import s from '../BaseQuestionnaireResponseForm.module.scss';
import { useFieldController } from '../hooks';

export function QuestionChoice({ parentPath, questionItem }: QuestionItemProps) {
    const { linkId, answerOption, repeats } = questionItem;
    const fieldName = [...parentPath, linkId];

    const { value, formItem, onChange, disabled } = useFieldController(fieldName, questionItem);

    const onSelect = useCallback((option: any) => onChange([].concat(option)), [onChange]);

    return (
        <Form.Item {...formItem} data-testid="question-choice">
            <ChoiceQuestionSelect
                options={answerOption!}
                value={value}
                onChange={onSelect}
                repeats={repeats}
                disabled={disabled}
            />
        </Form.Item>
    );
}

interface ChoiceQuestionSelectProps {
    value?: QuestionnaireResponseItemAnswer[];
    onChange: (...option: any[]) => void;
    options: QuestionnaireItemAnswerOption[];
    repeats?: boolean;
    disabled?: boolean;
    placeholder?: string;
}

export function ChoiceQuestionSelect(props: ChoiceQuestionSelectProps) {
    const { value, onChange, options, disabled } = props;

    return (
        <>
            <Select<QuestionnaireItemAnswerOption>
                value={value}
                options={options}
                isDisabled={disabled}
                className={s.select}
                onChange={(v) => onChange(v)}
                isOptionSelected={(option) =>
                    !!value && value?.findIndex((v) => _.isEqual(v?.value, option.value)) !== -1
                }
                getOptionLabel={(o) => (getDisplay(o.value) as string) || ''}
                classNamePrefix="react-select"
            />
        </>
    );
}
