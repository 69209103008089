import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { ServiceManager } from 'aidbox-react';
import { Button, Modal } from 'antd';
import { InitialDataContext } from 'containers/App/hooks';
import { UserRoleValue, getUserRoles } from 'containers/UserList/utils';
import { useContext } from 'react';
import { checkRolePermission } from 'utils/utils';

import { BoxsetListWithTotal } from '../hooks';
import s from './BoxsetsHeader.module.scss';
import { useBoxsetModal } from './hooks';

export function BoxsetsHeader(props: {
    reloadBoxsets: ServiceManager<BoxsetListWithTotal, any>['softReloadAsync'];
}) {
    const { confirmLoading, handleCancel, handleOk, openModal, showModal } = useBoxsetModal(
        props.reloadBoxsets,
    );
    const context = useContext(InitialDataContext);
    const userRoles: UserRoleValue[] = context?.user
        ? getUserRoles(context?.user)
        : ['unprivileged'];
    const allowAddBoxsetRoles: UserRoleValue[] = ['admin', 'clinicalResearchCoordinator'];

    return (
        <div className={s.heading}>
            <h2>Boxsets</h2>
            {checkRolePermission(allowAddBoxsetRoles, userRoles) ? (
                <Button icon={<PlusOutlined />} type="primary" onClick={showModal}>
                    Add boxset
                </Button>
            ) : null}
            <Modal
                title="Add new boxset"
                open={openModal}
                onCancel={handleCancel}
                closable={false}
                footer={[
                    <Button key="CancelBtn" onClick={handleCancel} disabled={confirmLoading}>
                        Cancel
                    </Button>,
                    <Button
                        type="primary"
                        key="OkBtn"
                        onClick={handleOk}
                        disabled={confirmLoading}
                        icon={confirmLoading ? <LoadingOutlined /> : <PlusOutlined />}
                    >
                        Add boxset
                    </Button>,
                ]}
            >
                <p>Are you sure you want to add new boxset?</p>
            </Modal>
        </div>
    );
}
