import { QuestionItemProps, useFieldController } from '@beda.software/fhir-questionnaire';
import {
    QuestionnaireResponseItemAnswer,
    QuestionnaireResponseItemAnswerValue,
} from '@beda.software/fhir-questionnaire/contrib/aidbox';
import classNames from 'classnames';

import s from './ReadonlyWidgets.module.scss';

export function QuestionChoice({ parentPath, questionItem }: QuestionItemProps) {
    const { linkId, text, repeats, hidden } = questionItem;
    const fieldName = repeats ? [...parentPath, linkId] : [...parentPath, linkId, 0];
    const { value } = useFieldController(fieldName, questionItem);

    if (hidden) {
        return null;
    }

    if (repeats) {
        return (
            <div className={classNames(s.question, s.row, 'form__question')}>
                <span className={s.questionText}>{text}</span>
                <span className={s.answer}>{getArrayDisplay(value) || '-'}</span>
            </div>
        );
    } else {
        return (
            <div className={classNames(s.question, s.row, 'form__question')}>
                <span className={s.questionText}>{text}</span>
                <span className={s.answer}>{getDisplay(value?.value) || '-'}</span>
            </div>
        );
    }
}

export function getDisplay(value?: QuestionnaireResponseItemAnswerValue): string | number | null {
    if (!value) {
        return null;
    }

    if (value.Coding) {
        return value.Coding.display ?? '';
    }

    if (value.string) {
        return value.string;
    }

    if (value.integer) {
        return value.integer;
    }

    if (value.decimal) {
        return value.decimal;
    }

    if (value.Reference && value.Reference.display) {
        return value.Reference.display;
    }

    console.warn(`There is not implementation for getDisplay of ${JSON.stringify(value)}`);

    return '';
}

export function getArrayDisplay(options?: QuestionnaireResponseItemAnswer[]): string | null {
    if (!options) {
        return null;
    }

    return options.map((v: QuestionnaireResponseItemAnswer) => getDisplay(v.value)).join(', ');
}
