import {
    createFHIRResource,
    getFHIRResources,
    saveFHIRResource,
    saveFHIRResources,
} from 'aidbox-react/lib/services/fhir';
import { SearchParams } from 'aidbox-react/lib/services/search';

import { AidboxResource } from '../contrib/aidbox';

const APP_ID = 'immunix';

export function getAppResources<R extends AidboxResource>(
    resourceType: R['resourceType'],
    searchParams: SearchParams,
    extraPath?: string,
) {
    return getFHIRResources<R>(resourceType, { ...searchParams, app: APP_ID }, extraPath);
}

export function saveAppResource<R extends AidboxResource>(resource: R) {
    return saveFHIRResource<R>({ ...resource, app: APP_ID });
}

export function saveAppResources<R extends AidboxResource>(
    resources: R[],
    bundleType: 'transaction' | 'batch',
) {
    const resourcesWithAppId = resources.map((resource) => {
        resource['app'] = APP_ID;
        return resource;
    });
    return saveFHIRResources<R>(resourcesWithAppId, bundleType);
}

export function createAppResource<R extends AidboxResource>(resource: R) {
    return createFHIRResource<R>({ ...resource, app: APP_ID });
}
