import { EditFilled } from '@ant-design/icons';
import { Button, Checkbox, Popover, Space, Table } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { TableLoading } from 'components/TableLoading';

import { RenderRemoteData } from 'aidbox-react/lib/components/RenderRemoteData';

import { AlertFailure } from '../../components/AlertFailure';
import { BaseLayout } from '../../components/BaseLayout';
import { User } from '../../contrib/aidbox';
import s from './UserList.module.scss';
import { useUserList } from './useUserList';
import { useUserRoleModal } from './useUserRoleModal';
import { UserRoleValue, getUserRoles, userRolesMap } from './utils';

export function UserList() {
    const { userListRD, reloadUserList } = useUserList();
    const {
        modalOpen,
        showUserRoleModal,
        closeModal,
        currentUser,
        saveUserRoles,
        currentRoles,
        onRoleChange,
    } = useUserRoleModal(reloadUserList);

    const columns = [
        {
            title: 'Email',
            key: 'email',
            dataIndex: 'email',
            render: (record: any, user: User) => user?.email || '-',
            width: '25%',
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            render: (record: any, user: User) => user?.data?.name || '-',
            width: '25%',
        },
        {
            title: 'User roles',
            key: 'role',
            dataIndex: 'role',
            render: (record: any, user: User) => {
                return (
                    <>
                        {getUserRoles(user).map((role) => {
                            return <div key={role}>{userRolesMap[role].name}</div>;
                        })}
                    </>
                );
            },
            width: '25%',
        },
        {
            title: 'Actions',
            key: 'actions',
            dataIndex: 'actions',
            render: (record: any, user: User) => (
                <Button
                    type="link"
                    onClick={() => {
                        showUserRoleModal(user);
                    }}
                    className={s.actionSpace}
                    icon={<EditFilled />}
                >
                    Edit
                </Button>
            ),
            width: '25%',
        },
    ];

    return (
        <BaseLayout showHeader={true} showMainMenu={true}>
            <Modal
                title={`Change user role for ${currentUser?.data?.name || 'user'}`}
                open={modalOpen}
                onOk={saveUserRoles}
                onCancel={closeModal}
                okText="Save"
            >
                <p>{currentUser?.email}</p>
                <h4>Choose a role:</h4>
                <Checkbox.Group
                    onChange={(newValues) => {
                        onRoleChange(newValues as UserRoleValue[]);
                    }}
                    value={currentRoles}
                >
                    <Space direction="vertical">
                        {Object.entries(userRolesMap).map(([key, { name, description }]) => (
                            <Popover
                                placement={'left'}
                                key={key}
                                content={getUserRoleDescription(description)}
                                title={name}
                            >
                                <Checkbox key={key} value={key}>
                                    {name}
                                </Checkbox>
                            </Popover>
                        ))}
                    </Space>
                </Checkbox.Group>
            </Modal>
            <div className={s.wrapper}>
                <RenderRemoteData
                    remoteData={userListRD}
                    renderLoading={() => <TableLoading baseColumns={columns} />}
                    renderFailure={(error) => <AlertFailure error={error} />}
                >
                    {(tableData) => {
                        return (
                            <Table
                                columns={columns}
                                dataSource={tableData}
                                rowKey={(record) => record.id!}
                            />
                        );
                    }}
                </RenderRemoteData>
            </div>
        </BaseLayout>
    );
}

function getUserRoleDescription(description: string[]) {
    return (
        <ul className={s.roleList}>
            {description.map((item) => (
                <li key={item}>{item}</li>
            ))}
        </ul>
    );
}
