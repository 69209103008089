import { WarningOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { InitialDataContext } from 'containers/App/hooks';
import { getUserRoles } from 'containers/UserList/utils';
import { useContext } from 'react';

import { BoxsetTableItem } from '..';
import { useApplicationConfig } from '../../../sharedState';
import { ModalRecord } from '../hooks';
import { getButtonTitle } from '../utils';

export function ChangeStatusButtons(props: {
    record: BoxsetTableItem;
    showModal: (modalData: ModalRecord) => void;
}) {
    const { record, showModal } = props;
    const context = useContext(InitialDataContext);
    const userRoles = context?.user ? getUserRoles(context?.user) : null;

    const applicationConfig = useApplicationConfig();
    if (!userRoles) {
        return (
            <Tooltip title="Error. UserRole is null. Contact the app administrator.">
                <WarningOutlined style={{ color: 'var(--red)' }} />
            </Tooltip>
        );
    }
    const statuses = applicationConfig.transitionMapStatuses[record.status.value] || [];

    return (
        <>
            {statuses.map((newStatus) => {
                const isDisabled = newStatus === 'sent-to-prenosis' && !record.boxsetFilled;
                return (
                    <Button
                        type="link"
                        onClick={() => showModal({ record, newStatus })}
                        key={newStatus}
                        disabled={isDisabled}
                        title={isDisabled ? 'Boxset is not filled' : undefined}
                    >
                        {getButtonTitle(record.status.value) || `Change status to ${newStatus}`}
                    </Button>
                );
            })}
        </>
    );
}
