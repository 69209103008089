import Table, { ColumnsType } from 'antd/es/table';
import { StatusTagNew } from 'components/StatusTag';
import { TableLoading } from 'components/TableLoading';
import { useNavigate } from 'react-router-dom';
import { LabSession } from 'shared/src/contrib/aidbox';
import { formatHumanDateTime } from 'utils/date';

import { RenderRemoteData } from 'aidbox-react/lib/components/RenderRemoteData';

import { BaseLayout } from '../../components/BaseLayout';
import { LabSessionTableItem, useLabSession } from './hooks';
import s from './Sessions.module.scss';
import { SessionHeader } from './SessionsHeader';
import { getLabSessionStep, getLabSessionTag } from './utils';

export function LabSessionList() {
    const {
        labSessionsRD,
        manager,
        setPageNumber,
        pageNumber,
        setPageSize,
        pageSize,
        totalSessions,
    } = useLabSession();
    const navigate = useNavigate();
    return (
        <BaseLayout showHeader={true} showMainMenu={true} className={s.wrapper}>
            <SessionHeader reloadLabSession={manager} />

            <RenderRemoteData
                remoteData={labSessionsRD}
                renderLoading={() => (
                    <TableLoading
                        pageNumber={pageNumber}
                        pageSize={pageSize}
                        total={totalSessions}
                        baseColumns={getLabSessionColumns()}
                    />
                )}
            >
                {({ labSessionItems, total }) => {
                    const paginationConfig = {
                        current: pageNumber,
                        pageSize: pageSize,
                        total: total,
                        pageSizeOptions: [10, 20, 50],
                        showSizeChanger: true,
                    };
                    return (
                        <Table
                            onRow={(record) => {
                                return {
                                    onClick: () => {
                                        navigate(`./${record.id}`);
                                    },
                                };
                            }}
                            className={s.table}
                            columns={getLabSessionColumns()}
                            dataSource={labSessionItems}
                            bordered
                            size="middle"
                            pagination={{
                                onChange: (newPage, newPageSize) => {
                                    setPageNumber(newPage);
                                    setPageSize(newPageSize);
                                },
                                ...paginationConfig,
                            }}
                            rowKey={(record) => record.id}
                        />
                    );
                }}
            </RenderRemoteData>
        </BaseLayout>
    );
}

export function getLabSessionColumns(): ColumnsType<LabSessionTableItem> {
    const boxsetColumns: ColumnsType<LabSessionTableItem> = [
        {
            title: 'Created',
            dataIndex: 'created_date',
            key: 'created_date',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 300,
            render: (status: LabSession['status']) => {
                return (
                    <div>
                        {status === 'completed' || status === 'cancelled' ? (
                            <StatusTagNew
                                getTagData={() => {
                                    return getLabSessionTag(status);
                                }}
                            />
                        ) : (
                            <>
                                <StatusTagNew
                                    getTagData={() => {
                                        return getLabSessionStep(status);
                                    }}
                                />
                            </>
                        )}
                    </div>
                );
            },
        },
        {
            title: 'Specimens count',
            dataIndex: 'specimens_count',
            key: 'specimens_count',
            width: 150,
        },
        {
            title: 'Min collected date',
            dataIndex: 'min_collected_date',
            key: 'min_collected_date',

            width: 170,
            render: (text) => {
                return <div>{formatHumanDateTime(text)}</div>;
            },
        },
        {
            title: 'Max collected date',
            dataIndex: 'max_collected_date',
            key: 'max_collected_date',
            width: 170,
            render: (text) => {
                return <div>{formatHumanDateTime(text)}</div>;
            },
        },
    ];
    return boxsetColumns;
}
