import { formatError } from 'aidbox-react';
import { notification, TableProps } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import { useState } from 'react';
import { getAppResources } from 'services/immunix';

import { useService } from 'aidbox-react/lib/hooks/service';
import { failure, isFailure, isSuccess } from 'aidbox-react/lib/libs/remoteData';
import { extractBundleResources } from 'aidbox-react/lib/services/fhir';
import { mapSuccess, service } from 'aidbox-react/lib/services/service';

import { BoxsetTableItem } from '.';
import { Boxset } from '../../contrib/aidbox';
import { useApplicationConfig } from '../../sharedState';
import { calculateFilteredStatuses, formatBoxSetsList } from './utils';

export interface BoxsetListWithTotal {
    boxsetTableItems: BoxsetTableItem[];
    total: number;
}

export interface ModalRecord {
    record: BoxsetTableItem;
    newStatus: Boxset['status'];
}

export function useBoxSets() {
    const applicationConfig = useApplicationConfig();

    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalBoxsets, setTotalBoxsets] = useState(0);

    const [openStatusConfirmModal, setOpenStatusConfirmModal] = useState(false);
    const [loadingStatusChange, setLoadingStatusChange] = useState(false);
    const [modalData, setModalData] = useState<ModalRecord | null>(null);

    const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});

    const [boxSetsRD, boxsetsManager] = useService(async () => {
        const response = await getAppResources<Boxset>('Boxset', {
            _sort: '-boxsetNumber',
            _count: pageSize,
            _page: pageNumber,
            status: calculateFilteredStatuses(
                applicationConfig.boxsetStatuses,
                filteredInfo.status,
            ),
        });

        if (isFailure(response)) {
            console.error({ message: JSON.stringify(response.error) });
        }

        return mapSuccess(response, (bundle) => {
            const resourcesMap = extractBundleResources(bundle);
            setTotalBoxsets(bundle.total!);
            const boxsetTableItems = formatBoxSetsList(resourcesMap.Boxset);
            return {
                boxsetTableItems,
                total: bundle.total!,
            };
        });
    }, [pageSize, pageNumber, filteredInfo.status]);

    const onStatusChange = async () => {
        setLoadingStatusChange(true);

        const boxsetId = modalData?.record.id;

        if (!boxsetId) {
            notification.error({ message: 'Boxset Id not Provided' });
            setLoadingStatusChange(false);
            setModalData(null);
            return failure('Boxset Id not Provided');
        }

        const response = await service({
            method: 'PATCH',
            url: `/Boxset/${boxsetId}/$update-status`,
            data: { status: modalData.newStatus },
        });

        if (isSuccess(response)) {
            await boxsetsManager.softReloadAsync();
            notification.success({ message: 'Boxset status updated' });
        }
        if (isFailure(response)) {
            notification.error({ message: formatError(response.error) });
        }
        setLoadingStatusChange(false);
        setModalData(null);
        return response;
    };

    const showModal = (record: ModalRecord) => {
        setModalData(record);
        setOpenStatusConfirmModal(true);
    };

    const handleChange: TableProps<BoxsetTableItem>['onChange'] = (_pagination, filters) => {
        setFilteredInfo(filters);
    };

    return {
        boxSetsRD,
        pageNumber,
        setPageNumber,
        pageSize,
        setPageSize,
        totalBoxsets,
        reloadBoxsets: boxsetsManager.softReloadAsync,
        openStatusConfirmModal,
        setOpenStatusConfirmModal,
        onStatusChange,
        showModal,
        modalData,
        loadingStatusChange,
        setModalData,
        handleChange,
        filteredInfo,
        applicationConfig,
    };
}
