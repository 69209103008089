import { Tabs } from 'antd';

import { useSessionEditMenu } from './hooks';
import s from './SessionEditMenu.module.scss';

export function SessionEditMenu() {
    const { activeKey, onChange, items } = useSessionEditMenu();
    return (
        <Tabs className={s.menuWrapper} activeKey={activeKey} items={items} onChange={onChange} />
    );
}
