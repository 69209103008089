export const humanReadableTaskStatus: Record<string, string> = {
    ready: 'Ready',
    'in-progress': 'In Progress',
    failed: 'Failed',
    completed: 'Completed',
};

export const taskStatusColorValues: Record<string, { color: string }> = {
    ready: { color: 'var(--teal-green)' },
    'in-progress': {
        color: 'var(--orange)',
    },
    failed: { color: 'var(--coral)' },
    completed: { color: 'var(--light-teal)' },
};
