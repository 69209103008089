import { useParams } from 'react-router-dom';
import { LabSession } from 'shared/src/contrib/aidbox';

import { useService } from 'aidbox-react/lib/hooks/service';
import { getFHIRResource, makeReference } from 'aidbox-react/lib/services/fhir';

export function useSessionPage() {
    const { labSessionId } = useParams();

    const [labSessionRD, labSessionManager] = useService(async () => {
        const response = await getFHIRResource<LabSession>(
            makeReference('LabSession', labSessionId!),
        );

        return response;
    });

    return { labSessionRD, sessionReload: labSessionManager['softReloadAsync'] };
}
