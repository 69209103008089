import { OperationOutcome } from '../contrib/aidbox';

interface BackendError {
    error: string;
    error_description: string;
}

const axiosNetworkError = 'Network Error';

function isObject(value: any): value is Object {
    return value && typeof value === 'object' && !(value instanceof Array);
}

export function isOperationOutcome(error: any): error is OperationOutcome {
    return isObject(error) && error.resourceType === 'OperationOutcome';
}

export function isBackendError(error: any): error is BackendError {
    return isObject(error) && error.error;
}

export function extractErrorCode(error: any) {
    if (error === axiosNetworkError) {
        return 'network_error';
    }

    if (isOperationOutcome(error)) {
        return error.issue[0].code;
    }

    if (isBackendError(error)) {
        return error.error;
    }

    return 'unknown';
}
