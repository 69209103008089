import { FlagOption, RawFlagOptionsStepOne, RawFlagOptionsStepTwo } from './types';

export const flagConfigValues = {
    ok: { color: 'var(--teal-green)', textColor: '#ffffff', title: '' },
    qns: { color: 'var(--dark-grey)', textColor: '#ffffff', title: 'Quantity is not sufficient' },
    excluded: { color: 'var(--teal)', textColor: '#ffffff', title: 'Sample is excluded' },
    na: { color: 'var(--red)', textColor: '#ffffff', title: 'Specimen is not available' },
    hemolyzed: { color: 'var(--peach)', textColor: '#ffffff' },
    icteric: { color: 'var(--light-peach)', textColor: 'var(--charcoal)' },
    lipemic: { color: 'var(--pale-grey)', textColor: 'var(--smoke-gray)' },
};

export function getFlagColorConfig(flag: string): {
    color: string;
    textColor: string;
    title: string;
} {
    return flagConfigValues[flag] || { color: 'default', textColor: '', title: '' };
}

export function getFlagOptions<T extends RawFlagOptionsStepOne | RawFlagOptionsStepTwo>(
    options: T,
): FlagOption<T>[] {
    return options.map((option) => ({
        value: option.value,
        label: option.text,
    }));
}
