import { formatError } from 'aidbox-react';
import { notification } from 'antd';
import { useDebounced } from 'hooks/debounced';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isFailure, isSuccess } from 'aidbox-react/lib/libs/remoteData';
import { service } from 'aidbox-react/lib/services/service';

import { useSet } from '../../../hooks/useSet';
import { StepOneFlagFilter } from '../FlagsCell/FlagsCellStepOne/flags';
import { LabSessionProps, StepOneTableData, TableStepOneProps } from '../types';
import { moveLabSessionToStep2 } from '../utils';

export function useStepOne(props: LabSessionProps) {
    const { labSession } = props;

    const [stepOneTableRD, manager] = useService(async () => {
        return await service<StepOneTableData[]>({
            method: 'GET',
            url: `/LabSession/${labSession.id}/$get-step-one-data`,
        });
    });

    return {
        stepOneTableRD,
        stepOneDataReload: manager.softReloadAsync,
    };
}

export function useStepOneSearch(props: TableStepOneProps) {
    const { stepOneTableData } = props;

    const [filteredTableData, setSearchTableData] =
        useState<Array<StepOneTableData>>(stepOneTableData);

    const [searchText, setSearchText] = useState('');
    const [flagFilters, setFlagFilters] = useState<StepOneFlagFilter[]>([]);

    const debouncedSearchText = useDebounced(searchText, 500);

    const filterData = useCallback(
        (record: StepOneTableData) => {
            const textSearchResult = Object.keys(record).some((key) => {
                const filterableColumns = ['patient_name', 'mrn', 'specimen_identifier', 'note'];

                return (
                    filterableColumns.includes(key) &&
                    String(record[key]).toLowerCase().includes(debouncedSearchText.toLowerCase())
                );
            });

            const filterSearchResult = flagFilters.length
                ? flagFilters.includes(record.flag)
                : true;

            return textSearchResult && filterSearchResult;
        },
        [debouncedSearchText, flagFilters],
    );

    useEffect(() => {
        let filterTable = stepOneTableData.filter((record) => filterData(record));

        setSearchTableData(filterTable);
    }, [debouncedSearchText, stepOneTableData, flagFilters, filterData]);

    return {
        filteredTableData,
        flagFilters,
        setSearchText,
        setFlagFilters,
    };
}

interface UseMoveStepTwoProps {
    sessionReload: LabSessionProps['sessionReload'];
    setFlagFilters: Dispatch<SetStateAction<StepOneFlagFilter[]>>;
}

export function useMoveStepTwo(props: UseMoveStepTwoProps) {
    const { sessionReload, setFlagFilters } = props;
    const [erroredRows, erroredRowsActions] = useSet<string>();

    const handleChangeToStep2 = useCallback(
        async (id: string) => {
            const response = await moveLabSessionToStep2(id);
            if (isFailure(response)) {
                const diagnosticsError = response.error?.issue?.[0]?.diagnostics;
                if (diagnosticsError?.id === 'flag_validation_error') {
                    notification.warning({ message: 'Please setup flags for each Specimen' });
                    erroredRowsActions.set(diagnosticsError.resources_ids);
                    setFlagFilters(['notSet']);
                } else {
                    notification.warning({ message: formatError(response.error) });
                }
            }
            if (isSuccess(response)) {
                await sessionReload();
                notification.success({ message: 'Success' });
            }
        },
        [erroredRowsActions, setFlagFilters, sessionReload],
    );

    return {
        handleChangeToStep2,
        erroredRows,
        removeRecordError: erroredRowsActions.remove,
    };
}
