import { initServices } from '@beda.software/fhir-react';

import { RemoteDataResult } from 'aidbox-react/lib/libs/remoteData';
import { resetInstanceToken, setInstanceToken } from 'aidbox-react/lib/services/instance';
import { service } from 'aidbox-react/lib/services/service';
import { Token } from 'aidbox-react/lib/services/token';
import { uuid4 } from 'aidbox-react/lib/utils/uuid';

import { User } from '../contrib/aidbox';

export const AUTHORIZATION_CODE_CLIENT_ID = 'spa-ac-immunix-implementation-client';

function initSdcServiceProvider() {
    let baseUrl;
    if (window && (window as any).__SPACONFIG__) {
        baseUrl = (window as any).__SPACONFIG__.baseURL;
    } else {
        baseUrl = 'http://localhost:8080';
    }
    return initServices(`${baseUrl}/fhir`);
}
export const serviceProvider = initSdcServiceProvider();

export interface AppToken extends Token {
    userinfo: any;
}

export interface SigninBody {
    email: string;
    password: string;
}

export function signin(data: SigninBody): Promise<RemoteDataResult<AppToken>> {
    return service({
        url: '/auth/token',
        method: 'POST',
        data: {
            username: data.email,
            password: data.password,
            client_id: 'SPA-immunix',
            grant_type: 'password',
        },
    });
}

export interface OAuth2AuthCodeAuthorize {
    codeVerifier: string;
    state: string;
}

export async function oAuth2AuthCodeAuthorize({ codeVerifier, state }: OAuth2AuthCodeAuthorize) {
    const baseUrl = (window as any).__SPACONFIG__.baseURL;
    const codeChallenge = await generateCodeChallenge(codeVerifier);
    window.location.href = `${baseUrl}/auth/authorize?response_type=code&client_id=${AUTHORIZATION_CODE_CLIENT_ID}&state=${codeVerifier}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
    return;
}

export async function generateCodeChallenge(codeVerifier: string) {
    var digest = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(codeVerifier));

    // @ts-ignore
    return (
        window
            // @ts-ignore
            .btoa(String.fromCharCode(...new Uint8Array(digest)))
            .replace(/=/g, '')
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
    );
}

export interface OAuth2AuthCodeGetTokenProps {
    code: string;
    codeVerifier: string;
}

export function oAuth2AuthCodeGetToken({
    code,
    codeVerifier,
}: OAuth2AuthCodeGetTokenProps): Promise<RemoteDataResult<AppToken>> {
    return service({
        url: '/auth/token',
        method: 'POST',
        data: {
            client_id: AUTHORIZATION_CODE_CLIENT_ID,
            code_verifier: codeVerifier,
            code: code,
            grant_type: 'authorization_code',
        },
    });
}

export const setToken = (token: Token) => {
    setInstanceToken(token);
    serviceProvider.setInstanceToken(token);
    localStorage.setItem('token', JSON.stringify(token));
};

export function getUserInfo() {
    return service<User>({
        method: 'GET',
        url: '/auth/userinfo',
    });
}

export function getToken() {
    return localStorage.getItem('token') || undefined;
}

export function removeToken() {
    localStorage.removeItem('token');
}

export function setSessionId() {
    const id = uuid4();
    sessionStorage.setItem('sessionId', JSON.stringify(id));
}

export function getSessionid() {
    return sessionStorage.getItem('sessionId') || undefined;
}

function deleteSession() {
    return service({
        url: '/Session',
        method: 'DELETE',
    });
}

export function removeSessionId() {
    sessionStorage.removeItem('sessionId');
}

export function baseLogout() {
    deleteSession();
    resetInstanceToken();
    serviceProvider.resetInstanceToken();
    removeToken();
    removeSessionId();
    window.location.href = '/login';
}

export function generateRandomString(length: number) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export async function sha256(message: string) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}
