import { Menu } from 'antd';

import s from './AppMenu.module.scss';
import { useAppMenu } from './useAppMenu';

export function AppMenu() {
    const { selectedKey, menuItems, onMenuItemClick } = useAppMenu();

    return (
        <Menu
            className={s.menu}
            onClick={onMenuItemClick}
            selectedKeys={[selectedKey]}
            mode="horizontal"
            items={menuItems}
        ></Menu>
    );
}
