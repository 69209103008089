import { Breadcrumb, Card } from 'antd';
import { AlertFailure } from 'components/AlertFailure';
import { BaseQuestionnaireResponseForm } from 'components/BaseQuestionnaireResponseForm/QuestionnaireResponseForm';
import { InitialDataContext } from 'containers/App/hooks';
import { FormSkeleton } from 'containers/SurveyForm';
import { useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { RenderRemoteData } from 'aidbox-react/lib';

import { BaseLayout } from '../../components/BaseLayout';
import { useRejectedSurvey } from './hooks';
import s from './SurveyRejectedForm.module.scss';

export function SurveyRejectedForm() {
    const { resourcesRD, onSuccess, onFailure } = useRejectedSurvey();
    const navigate = useNavigate();

    const context = useContext(InitialDataContext);
    const user = context?.user;
    return (
        <BaseLayout showHeader={true} showMainMenu={true}>
            <Breadcrumb>
                <Breadcrumb.Item
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    <a>Surveys</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>New Survey</Breadcrumb.Item>
            </Breadcrumb>
            <h2>Rejected Response</h2>
            <RenderRemoteData
                remoteData={resourcesRD}
                renderLoading={() => (
                    <Card className={s.wrapper}>
                        <FormSkeleton />
                    </Card>
                )}
                renderFailure={(error) => <AlertFailure error={error} />}
            >
                {({ task, patient, questionnaire }) => {
                    const qr_id = task.output?.[0].value?.Reference?.id;
                    if (qr_id) {
                        return <Navigate to={`/surveys/${task.id}/${qr_id}`} />;
                    }
                    return (
                        <Card className={s.wrapper}>
                            <BaseQuestionnaireResponseForm
                                onFailure={onFailure}
                                onSuccess={onSuccess}
                                questionnaireId="rejected-survey"
                                remoteDataRenderConfig={{
                                    renderLoading: () => <FormSkeleton />,
                                    renderFailure(error) {
                                        return <AlertFailure error={error} />;
                                    },
                                }}
                                questionnaire={questionnaire}
                                launchContextParameters={[
                                    {
                                        name: 'Task',
                                        resource: task,
                                    },
                                    {
                                        name: 'Patient',
                                        resource: patient,
                                    },
                                    {
                                        name: 'User',
                                        resource: user as any,
                                    },
                                ]}
                            />
                        </Card>
                    );
                }}
            </RenderRemoteData>
        </BaseLayout>
    );
}
