import { ServiceManager } from 'aidbox-react';
import { notification } from 'antd';
import { getDescription } from 'components/AlertFailure';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { isFailure, isSuccess } from 'aidbox-react/lib/libs/remoteData';

import { SessionListWithTotal } from '../hooks';
import { createLabSession } from '../utils';

export function useSessionModal(
    reloadLabSessions: ServiceManager<SessionListWithTotal, any>['softReloadAsync'],
) {
    const [openModal, setOpenModal] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const navigate = useNavigate();

    const showModal = () => {
        setOpenModal(true);
    };

    const handleCreate = async () => {
        setConfirmLoading(true);
        const response = await createLabSession();

        if (isSuccess(response)) {
            await reloadLabSessions();
            setOpenModal(false);
            notification.success({ message: 'New session created' });
            navigate(`./${response.data.id}`);
        }
        if (isFailure(response)) {
            const description = getDescription(response.error);
            setOpenModal(false);
            notification.warning({
                description: description,
                message: 'New session not created',
            });
        }
        setConfirmLoading(false);
        return response;
    };

    const handleCancel = () => {
        setOpenModal(false);
    };

    return { openModal, confirmLoading, showModal, handleCreate, handleCancel };
}
