import { formatError } from 'aidbox-react';
import notification from 'antd/es/notification';
import { useState } from 'react';

import {
    RemoteData,
    failure,
    isFailure,
    isSuccess,
    loading,
    success,
} from 'aidbox-react/lib/libs/remoteData';

import { AliquotsSelectProps } from './types';
import { UpdateAliquotsParams, validateAndUpdateAliquots } from './utils';

export function useAliquotsSelect(props: AliquotsSelectProps) {
    const { aliquot, record, boxsetArrangementId, reload, removeRecordError } = props;
    const tubeType = aliquot.tubeType;
    const aliquotsValues = record.aliquots;
    const value = aliquotsValues[aliquot.tubeType];

    const [validationResultRD, setValidationResultRD] = useState<RemoteData<string>>(success(''));
    const isLoading = validationResultRD === loading;

    const onAliquotChange = async (amount: number) => {
        setValidationResultRD(loading);

        const updateAliquotsData: UpdateAliquotsParams = {
            tube_type: tubeType,
            aliquots: { ...aliquotsValues, [tubeType]: amount },
            boxset_arrangement_id: boxsetArrangementId,
            sample_number: record.labSampleItem,
        };
        const response = await validateAndUpdateAliquots(updateAliquotsData);
        if (isSuccess(response)) {
            removeRecordError?.(record.id);
            setValidationResultRD(success(''));
        }
        if (isFailure(response)) {
            setValidationResultRD(failure(formatError(response.error)));
            notification.error({ message: formatError(response.error) });
        }
        await reload();
    };

    const options = Array.from({ length: props.aliquot.count + 1 }, (_, i) => ({
        value: i,
        label: i,
    }));

    return { value, options, onAliquotChange, isLoading };
}
