import { createSharedState } from 'aidbox-react/lib/hooks/shared-state';

import { Boxset } from '../contrib/aidbox';

export type HumanReadableStatuses = {
    [key in Boxset['status']]: string;
};

export interface ApplicationConfig {
    transitionMapStatuses: Partial<Record<Boxset['status'], Boxset['status'][]>>;
    boxsetStatuses: Boxset['status'][];
    humanReadableBoxsetStatuses: HumanReadableStatuses;
    specimenIdColumnName: string;
}

export const sharedApplicationConfig = createSharedState<ApplicationConfig | null>(null);

export function useApplicationConfig() {
    const applicationConfig = sharedApplicationConfig.getSharedState();
    if (applicationConfig === null) {
        throw new Error('applicationConfig is null error');
    }
    return applicationConfig;
}
