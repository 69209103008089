import { Steps } from 'antd';
import { LabSession } from 'shared/src/contrib/aidbox';

import s from './SessionSteps.module.scss';

export function SessionSteps(props: { status: LabSession['status'] }) {
    let currentStep = getCurrenStep(props.status);

    return (
        <Steps
            className={s.stepper}
            current={currentStep}
            size="small"
            items={[
                {
                    title: 'Collect and check specimens',
                },
                {
                    title: 'Aliquoting into Prenosis boxes',
                },
                {
                    title: 'Provide frozen time',
                },
            ]}
        ></Steps>
    );
}

function getCurrenStep(status: LabSession['status']) {
    switch (status) {
        case 'collect-and-evaluate':
            return 0;
        case 'aliquoting':
            return 1;
        case 'freeze-tubes':
            return 2;
        default:
            return 0;
    }
}
