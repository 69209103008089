import { LogoutOutlined } from '@ant-design/icons';
import { Button, Layout } from 'antd';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from '../../../images/Logo.svg';
import s from './AppHeader.module.scss';
import { useAppHeader } from './useAppHeader';

const { Header } = Layout;

export function AppHeader() {
    const { userData, dropCap, userDisplay, logout } = useAppHeader();
    const navigate = useNavigate();
    const siteLogo = (window as any).__SPACONFIG__.siteLogoURL;

    return (
        <Header className={s.wrapper}>
            <div className={s.leftSide}>
                <Logo
                    className={s.logo}
                    onClick={() => {
                        navigate('/');
                    }}
                />
                <span className={s.title}>Specimen Management</span>
            </div>
            <div className={s.rightSide}>
                {siteLogo && (
                    <div className={s.siteLogo}>
                        <img src={siteLogo} alt="logo" />
                    </div>
                )}
                {userData && (
                    <div className={s.userData}>
                        <div className={s.nameContainer}>
                            <div className={s.nameInitial}>{dropCap}</div>
                            <span className={s.fullname}>{userDisplay}</span>
                        </div>
                        <Button type="primary" className={s.logout} onClick={logout}>
                            <LogoutOutlined />
                            Log out
                        </Button>
                    </div>
                )}
            </div>
        </Header>
    );
}
