import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { ServiceManager } from 'aidbox-react';
import { Button, Modal } from 'antd';
import { InitialDataContext } from 'containers/App/hooks';
import { getUserRoles, UserRoleValue } from 'containers/UserList/utils';
import { useContext } from 'react';
import { checkRolePermission } from 'utils/utils';

import { SessionListWithTotal } from '../hooks';
import { useSessionModal } from './hooks';
import s from './SessionHeader.module.scss';

export function SessionHeader(props: {
    reloadLabSession: ServiceManager<SessionListWithTotal, any>['softReloadAsync'];
}) {
    const { confirmLoading, handleCancel, handleCreate, openModal, showModal } = useSessionModal(
        props.reloadLabSession,
    );

    const context = useContext(InitialDataContext);
    const userRoles: UserRoleValue[] = context?.user
        ? getUserRoles(context?.user)
        : ['unprivileged'];
    const allowAddLabSessionRoles: UserRoleValue[] = [
        'admin',
        'clinicalResearchCoordinator',
        'labTechnician',
    ];

    return (
        <div className={s.heading}>
            <h2>Sessions</h2>
            {checkRolePermission(allowAddLabSessionRoles, userRoles) ? (
                <Button icon={<PlusOutlined />} type="primary" onClick={showModal}>
                    Create session
                </Button>
            ) : null}
            <Modal
                title="Add new session"
                open={openModal}
                onCancel={handleCancel}
                closable={false}
                footer={[
                    <Button key="CancelBtn" onClick={handleCancel} disabled={confirmLoading}>
                        Cancel
                    </Button>,
                    <Button
                        type="primary"
                        key="OkBtn"
                        onClick={handleCreate}
                        disabled={confirmLoading}
                        icon={confirmLoading ? <LoadingOutlined /> : <PlusOutlined />}
                    >
                        Add session
                    </Button>,
                ]}
            >
                <p>Are you sure you want to create new session?</p>
            </Modal>
        </div>
    );
}
