import { QuestionItemProps } from '@beda.software/fhir-questionnaire';
import { Form, Input } from 'antd';

import { useFieldController } from '../hooks';
import s from './styles.module.scss';

export function QuestionString({ parentPath, questionItem }: QuestionItemProps) {
    const { linkId } = questionItem;
    const fieldName = [...parentPath, linkId, 0, 'value', 'string'];
    const { value, onChange, disabled, formItem, onBlur } = useFieldController(
        fieldName,
        questionItem,
    );

    return (
        <Form.Item {...formItem}>
            <Input
                value={value}
                disabled={disabled}
                onChange={onChange}
                onBlur={onBlur}
                className={s.simpleInput}
            />
        </Form.Item>
    );
}

export function QuestionText({ parentPath, questionItem }: QuestionItemProps) {
    const { linkId } = questionItem;
    const fieldName = [...parentPath, linkId, 0, 'value', 'string'];
    const { value, onChange, disabled, formItem } = useFieldController(fieldName, questionItem);

    return (
        <Form.Item {...formItem} data-testid={linkId}>
            <Input.TextArea value={value} disabled={disabled} onChange={onChange} />
        </Form.Item>
    );
}
