import { Progress, Tag, Tooltip } from 'antd';
import { BoxsetTableItem } from 'containers/Boxsets';
import { getTagData } from 'containers/Boxsets/utils';
import { ReactNode } from 'react';

import { useApplicationConfig } from '../../sharedState';

export function StatusTag(props: {
    statusItem: BoxsetTableItem['status'];
    showProgress?: boolean;
}) {
    const { statusItem, showProgress } = props;

    const applicationConfig = useApplicationConfig();
    const tagData = getTagData(statusItem.value, applicationConfig);
    if (statusItem.value === 'in-hospital' && showProgress) {
        return (
            <Tooltip title={statusItem.title}>
                <Tag
                    icon={tagData.icon}
                    color={tagData.color}
                    children={
                        <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                            <Progress
                                type="circle"
                                percent={statusItem.filedSamplesPercentage}
                                size={10}
                                showInfo={false}
                                strokeColor={'#ffffff'}
                                trailColor={'#ffffff50'}
                                strokeWidth={12}
                                strokeLinecap="square"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            />
                            {tagData.name}
                        </div>
                    }
                />
            </Tooltip>
        );
    }
    return <Tag icon={tagData.icon} color={tagData.color} children={tagData.name} />;
}

export interface TagData {
    name: string;
    color?: string;
    icon?: ReactNode;
}

export function StatusTagNew(props: { getTagData: () => TagData }) {
    const { getTagData } = props;
    const tagData = getTagData();
    return <Tag icon={tagData.icon} color={tagData.color} children={tagData.name} />;
}
