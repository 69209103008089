import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { SpecimentIdColumntTitle } from 'containers/LabSessionPage/SpecimentIdColumntTitle';

import s from './IdentifierHeader.module.scss';

export function IdentifierHeader() {
    const columnName = SpecimentIdColumntTitle();
    return (
        <div className={s.identColumnHeader}>
            <span>{columnName}</span>
            <Tooltip title="Click to copy column value">
                <InfoCircleOutlined className={s.icon} />
            </Tooltip>
        </div>
    );
}
