import { Button, Table } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { FilterValue } from 'antd/es/table/interface';
import { StatusTag } from 'components/StatusTag';
import { TableLoading } from 'components/TableLoading';
import { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { ApplicationConfig } from 'sharedState';
import '../../styles/colors.scss';

import { RenderRemoteData } from 'aidbox-react/lib/components/RenderRemoteData';

import { AlertFailure } from '../../components/AlertFailure';
import { BaseLayout } from '../../components/BaseLayout';
import { Boxset } from '../../contrib/aidbox';
import s from './Boxsets.module.scss';
import { BoxsetsHeader } from './BoxsetsHeader';
import { ChangeStatusButtons } from './ChangeStatusButtons';
import { ChangeStatusModal } from './ChangeStatusModal';
import { ModalRecord, useBoxSets } from './hooks';
import { getFilters } from './utils';

export interface BoxsetTableItem {
    id: string;
    boxsetFilled: boolean;
    boxsetNumber: number;
    sampleStart: number;
    sampleEnd: number;
    status: { value: Boxset['status']; filedSamplesPercentage: number; title: string };
}

export function Boxsets() {
    const {
        boxSetsRD,
        pageSize,
        setPageSize,
        pageNumber,
        setPageNumber,
        totalBoxsets,
        reloadBoxsets,
        openStatusConfirmModal,
        setOpenStatusConfirmModal,
        showModal,
        modalData,
        onStatusChange,
        loadingStatusChange,
        handleChange,
        filteredInfo,
        applicationConfig,
    } = useBoxSets();

    return (
        <BaseLayout showHeader={true} showMainMenu={true} className={s.wrapper}>
            <BoxsetsHeader reloadBoxsets={reloadBoxsets} />
            <ChangeStatusModal
                modalData={modalData}
                open={openStatusConfirmModal}
                setOpenModal={setOpenStatusConfirmModal}
                loading={loadingStatusChange}
                onStatusChange={onStatusChange}
            />
            <RenderRemoteData
                remoteData={boxSetsRD}
                renderLoading={() => (
                    <TableLoading
                        pageNumber={pageNumber}
                        pageSize={pageSize}
                        total={totalBoxsets}
                        baseColumns={getBoxsetColumns(showModal, filteredInfo, applicationConfig)}
                    />
                )}
                renderFailure={(error) => <AlertFailure error={error} />}
            >
                {({ boxsetTableItems }) => (
                    <BoxsetsTable
                        columns={getBoxsetColumns(showModal, filteredInfo, applicationConfig)}
                        boxsetTableItems={boxsetTableItems}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        total={totalBoxsets}
                        handleChange={handleChange}
                    />
                )}
            </RenderRemoteData>
        </BaseLayout>
    );
}

export function BoxsetsTable(props: {
    columns: ColumnsType<any>;
    boxsetTableItems: BoxsetTableItem[];
    pageNumber: number;
    pageSize: number;
    total: number;
    setPageSize: Dispatch<SetStateAction<number>>;
    setPageNumber: Dispatch<SetStateAction<number>>;
    handleChange: TableProps<BoxsetTableItem>['onChange'];
}) {
    const {
        boxsetTableItems,
        pageNumber,
        pageSize,
        total,
        setPageNumber,
        setPageSize,
        columns,
        handleChange,
    } = props;

    return (
        <Table
            columns={columns}
            dataSource={boxsetTableItems}
            bordered
            onChange={handleChange}
            size="middle"
            pagination={{
                current: pageNumber,
                pageSize: pageSize,
                total: total,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                onChange: (newPage, newPageSize) => {
                    setPageNumber(newPage);
                    setPageSize(newPageSize);
                },
                pageSizeOptions: [5, 10, 20, 50],
                showSizeChanger: true,
            }}
            rowKey={(record) => record.id}
        />
    );
}

export function getBoxsetColumns(
    showModal: (modalData: ModalRecord) => void,
    filteredInfo: Record<string, FilterValue | null>,
    applicationConfig: ApplicationConfig,
): ColumnsType<BoxsetTableItem> {
    const boxsetColumns: ColumnsType<BoxsetTableItem> = [
        {
            title: 'Boxset ID',
            dataIndex: 'boxsetNumber',
            key: 'boxsetNumber',
            width: '120px',
        },
        {
            title: 'Sample start',
            dataIndex: 'sampleStart',
            key: 'sampleStart',
            width: '240px',
        },
        {
            title: 'Sample end',
            dataIndex: 'sampleEnd',
            key: 'sampleEnd',
            width: '240px',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            defaultFilteredValue: filteredInfo.status,
            filters: getFilters(applicationConfig),
            render: (status: BoxsetTableItem['status']) => {
                return <StatusTag statusItem={status} showProgress />;
            },
        },
        {
            title: 'Actions',
            dataIndex: 'boxsetNumber',
            key: 'actions',
            width: '300px',
            render: (boxsetNumber: number, record) => {
                return (
                    <>
                        <Button type="link">
                            <Link to={`/boxsets/${boxsetNumber}`}>View details</Link>
                        </Button>
                        <ChangeStatusButtons record={record} showModal={showModal} />
                    </>
                );
            },
        },
    ];
    return boxsetColumns;
}
