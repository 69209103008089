import { formatError } from 'aidbox-react';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { LabSession } from 'shared/src/contrib/aidbox';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isFailure, isSuccess } from 'aidbox-react/lib/libs/remoteData';
import { service } from 'aidbox-react/lib/services/service';

import { LabSessionProps, StepThreeTableData } from '../types';
import { UpdateFrozenDateTimeData } from './types';

export function useStepThree(props: LabSessionProps) {
    const { labSession } = props;
    const [stepThreeRD, manager] = useService(async () => {
        return await service<StepThreeTableData[]>({
            url: `LabSession/${labSession.id}/$get-step-3-data`,
        });
    });

    return { stepThreeRD, stepThreeDataReload: manager.softReloadAsync };
}

export function useCompleteSession(labSession: LabSession) {
    const navigate = useNavigate();

    const completeSession = useCallback(async () => {
        const response = await service({
            method: 'PATCH',
            url: `/LabSession/${labSession.id}/$complete`,
        });
        if (isFailure(response)) {
            notification.error({ message: formatError(response.error) });
        }
        if (isSuccess(response)) {
            navigate(`/sessions/${labSession.id}?target=freeze-tubes`);
            location.reload();
        }
    }, [labSession.id]);

    return { completeSession };
}

export function useUpdateFrozenDateTime(labSession: LabSession, stepThreeDataReload: () => void) {
    const updateFrozenDateTime = useCallback(
        async (data: UpdateFrozenDateTimeData) => {
            const response = await service({
                method: 'PATCH',
                url: `/LabSampleItem/$update-frozen-datetime`,
                data: {
                    labSessionId: labSession.id,
                    ...data,
                },
            });
            if (isFailure(response)) {
                notification.error({ message: formatError(response.error) });
            }
            if (isSuccess(response)) {
                notification.success({ message: 'Frozen date and time updated' });
                stepThreeDataReload();
            }
        },
        [labSession.id],
    );

    return { updateFrozenDateTime };
}
