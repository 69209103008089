import { RenderRemoteData } from 'aidbox-react';
import { Button, ConfigProvider } from 'antd';
import { LoginIn } from 'components/LoginIn';
import BoxsetDetails from 'containers/BoxsetDetails';
import { Boxsets } from 'containers/Boxsets';
import { LabSessionList } from 'containers/LabSessionList';
import { LabSessionPage } from 'containers/LabSessionPage';
import { OrderList } from 'containers/OrderList';
import { SurveyForm } from 'containers/SurveyForm';
import { SurveyNewForm } from 'containers/SurveyNewForm';
import { SurveyRejectedForm } from 'containers/SurveyRejectedForm';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { checkRolePermission } from 'utils/utils';

import { AlertFailure } from '../../components/AlertFailure';
import { BaseLayout } from '../../components/BaseLayout';
import { DotPulseLoader } from '../../components/DotPulseLoader';
import { User } from '../../contrib/aidbox';
import { baseLogout } from '../../services/auth';
import { customTheme } from '../../utils/antdTheme';
import { OAuthCallback } from '../OAuthCallback';
import { SignIn } from '../SignIn';
import { UserList } from '../UserList';
import { UserRoleValue, getUserRoles } from '../UserList/utils';
import s from './App.module.scss';
import { InitialDataContext, useApp } from './hooks';

function App() {
    const { initialDataRD } = useApp();
    return (
        <ConfigProvider theme={customTheme}>
            <div className={s.app}>
                <BrowserRouter>
                    <RenderRemoteData
                        remoteData={initialDataRD}
                        renderLoading={() => <DotPulseLoader />}
                        renderFailure={(error) => (
                            <>
                                <BaseLayout showHeader={true}>
                                    <div className={s.wrapper}>
                                        <Button
                                            type="primary"
                                            className={s.buttonLogout}
                                            onClick={() => baseLogout()}
                                        >
                                            Log out
                                        </Button>
                                        <AlertFailure error={error} />
                                    </div>
                                </BaseLayout>
                            </>
                        )}
                    >
                        {({ user }) => <RoutesWrapper user={user} />}
                    </RenderRemoteData>
                </BrowserRouter>
            </div>
        </ConfigProvider>
    );
}

function RoutesWrapper(props: { user: User | null }) {
    const { user } = props;

    const allowSpecimenRoutes_Roles: UserRoleValue[] = [
        'admin',
        'labTechnician',
        'clinicalResearchCoordinator',
    ];
    const allowSurveyRoutes_Roles: UserRoleValue[] = ['admin', 'surveyCoordinator'];
    const allowUsersList_Roles: UserRoleValue[] = ['admin'];
    let location = useLocation();
    return (
        <InitialDataContext.Provider value={{ user }}>
            <Routes>
                {user ? (
                    <>
                        {checkRolePermission(allowSpecimenRoutes_Roles, getUserRoles(user)) ? (
                            <>
                                <Route path="/sessions" element={<LabSessionList />} />
                                <Route
                                    path="/sessions/:labSessionId"
                                    element={<LabSessionPage />}
                                />
                                <Route path="/boxsets" element={<Boxsets />} />
                                <Route path="/boxsets/:boxsetNumber" element={<BoxsetDetails />} />
                            </>
                        ) : null}
                        {checkRolePermission(allowSurveyRoutes_Roles, getUserRoles(user)) ? (
                            <>
                                <Route path="/surveys" element={<OrderList />} />
                                <Route path="/surveys/:id/new/" element={<SurveyNewForm />} />
                                <Route
                                    path="/surveys/:id/rejected-survey/"
                                    element={<SurveyRejectedForm />}
                                />

                                <Route path="/surveys/:id/:qr_id" element={<SurveyForm />} />
                            </>
                        ) : null}
                        {checkRolePermission(allowUsersList_Roles, getUserRoles(user)) && (
                            <Route path="/users" element={<UserList />} />
                        )}
                        <Route
                            path="*"
                            element={
                                <Navigate
                                    to={
                                        getUserRoles(user).includes('surveyCoordinator')
                                            ? '/surveys'
                                            : '/sessions'
                                    }
                                />
                            }
                        />
                    </>
                ) : (
                    <>
                        <Route path="callback" element={<OAuthCallback />} />
                        <Route path="signin" element={<SignIn />} />
                        <Route path="login" element={<LoginIn />} />
                        <Route
                            path="*"
                            element={
                                <Navigate to="/login" state={{ entryPoint: location.pathname }} />
                            }
                        />
                    </>
                )}
            </Routes>
        </InitialDataContext.Provider>
    );
}

export default App;
