export const stepOneFlagsOptions = [
    {
        text: 'OK',
        value: 'ok',
    },
    {
        text: 'QNS',
        value: 'qns',
    },
    {
        text: 'Excluded',
        value: 'excluded',
    },
    {
        text: 'Not Available',
        value: 'na',
    },
] as const;

export type StepOneFlag = (typeof stepOneFlagsOptions)[number]['value'];

export const stepOneFlagsFilters = [
    ...stepOneFlagsOptions,
    {
        text: 'Not Set',
        value: 'notSet',
    },
] as const;

export type StepOneFlagFilter = (typeof stepOneFlagsFilters)[number]['value'];
