import { SpecimenItem } from 'components/SpecimenItem';

import s from './BoxsetMapLegend.module.scss';

export function BoxsetMapLegend() {
    return (
        <div className={s.wrapper}>
            <div className={s.legendItem}>
                <SpecimenItem number={0} frozen={true} containsAliquot={true}></SpecimenItem>
                <span className={s.legendText}>Previous Frozen</span>
            </div>
            <div className={s.legendItem}>
                <SpecimenItem number={0} currenProcessed containsAliquot={true}></SpecimenItem>
                <span className={s.legendText}>Filled</span>
            </div>
            <div className={s.legendItem}>
                <SpecimenItem number={0} frozen={true} containsAliquot={false}></SpecimenItem>
                <span className={s.legendText}>Empty Aliquot</span>
            </div>
            <div className={s.legendItem}>
                <SpecimenItem number={0}></SpecimenItem>
                <span className={s.legendText}>Unprocessed</span>
            </div>
        </div>
    );
}
