import { useContext } from 'react';
import { getUserDisplay } from 'utils/getUserDisplay';

import { InitialDataContext } from '../../../containers/App/hooks';
import { baseLogout, getSessionid } from '../../../services/auth';

const sessionId = getSessionid();

export const useAppHeader = () => {
    const contextData = useContext(InitialDataContext);

    const userData = contextData?.user;

    const userDisplay = userData ? getUserDisplay(userData) : 'email';

    const dropCap = userDisplay[0].toUpperCase();

    const logout = (): void => {
        console.log(JSON.stringify({ text: 'User logged out', sessionId }));
        baseLogout();
    };

    return { userData, dropCap, userDisplay, logout };
};
