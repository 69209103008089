import { ItemType } from 'antd/es/menu/hooks/useItems';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkRolePermission } from 'utils/utils';

import { InitialDataContext } from '../../../containers/App/hooks';
import { getUserRoles, UserRoleValue } from '../../../containers/UserList/utils';

export interface MenuItem {
    key: string;
    path: string;
    title: string;
    roles: UserRoleValue[];
}

const mainMenu: MenuItem[] = [
    {
        key: 'sessions',
        path: '/sessions',
        title: 'Sessions',
        roles: ['admin', 'clinicalResearchCoordinator', 'labTechnician'],
    },
    {
        key: 'boxsets',
        path: '/boxsets',
        title: 'Boxsets',
        roles: ['admin', 'clinicalResearchCoordinator', 'labTechnician'],
    },
    {
        key: 'surveys',
        path: '/surveys',
        title: 'Surveys',
        roles: ['admin', 'surveyCoordinator'],
    },
    {
        key: 'users',
        path: '/users',
        title: 'Users',
        roles: ['admin'],
    },
];

function getUserMenu(rawMenu: MenuItem[], userRoles?: UserRoleValue[]): ItemType[] {
    if (!userRoles) {
        return [];
    }
    const filteredRawItems = rawMenu.filter((rawItem) =>
        checkRolePermission(rawItem.roles, userRoles),
    );
    return filteredRawItems.map((rawItem) => ({
        key: rawItem.key,
        label: rawItem.title,
    }));
}

export const useAppMenu = () => {
    const { pathname } = useLocation();

    const navigate = useNavigate();

    const selectedKey = pathname.split('/')[1];

    const contextData = useContext(InitialDataContext);
    const user = contextData?.user;
    const userRoles = user ? getUserRoles(user) : undefined;

    const menuItems = getUserMenu(mainMenu, userRoles);

    function onMenuItemClick(e: { key: string }) {
        const path = mainMenu.find((item) => item.key === e.key)?.path;
        if (path) {
            navigate(path);
        }
    }

    return { selectedKey, menuItems, onMenuItemClick };
};
