import { Button, Table } from 'antd';
import { useState } from 'react';

import { RenderRemoteData } from 'aidbox-react/lib/components/RenderRemoteData';

import { LabSessionEditControl } from '../LabSessionEditControl';
import { LabSessionProps, StepThreeTableData } from '../types';
import { getStepThreeColumns } from './columns';
import { useCompleteSession, useStepThree, useUpdateFrozenDateTime } from './hooks';
import s from './TableStepThree.module.scss';

export function TableStepThreeContainer(props: LabSessionProps) {
    const { stepThreeRD, stepThreeDataReload } = useStepThree(props);
    return (
        <RenderRemoteData remoteData={stepThreeRD}>
            {(stepThreeTableData) => {
                return (
                    <LabSessionTableStepThree
                        stepThreeTableData={stepThreeTableData}
                        stepThreeDataReload={stepThreeDataReload}
                        labSession={props.labSession}
                    />
                );
            }}
        </RenderRemoteData>
    );
}

interface TableStepThreeProps {
    stepThreeTableData: StepThreeTableData[];
    stepThreeDataReload: () => void;
    labSession: LabSessionProps['labSession'];
}

export function LabSessionTableStepThree(props: TableStepThreeProps) {
    const { stepThreeTableData, stepThreeDataReload, labSession } = props;

    const [viewOnly, setViewOnly] = useState(labSession.status !== 'freeze-tubes');

    const { completeSession } = useCompleteSession(labSession);
    const { updateFrozenDateTime } = useUpdateFrozenDateTime(labSession, stepThreeDataReload);

    const columns = getStepThreeColumns(updateFrozenDateTime, viewOnly);

    return (
        <>
            <div className={s.actionsBlock}>
                <div className={s.actionButtons}>
                    {labSession.status === 'completed' && (
                        <LabSessionEditControl
                            labSession={labSession}
                            viewOnly={viewOnly}
                            setViewOnly={setViewOnly}
                        />
                    )}
                </div>
            </div>
            <Table
                columns={columns}
                dataSource={stepThreeTableData}
                bordered
                rowKey={(record) => record.boxsetId}
                pagination={false}
            ></Table>
            {labSession.status === 'freeze-tubes' && (
                <Button
                    type="primary"
                    style={{ marginTop: 16, marginBottom: 16, float: 'right' }}
                    onClick={() => completeSession()}
                >
                    Complete session
                </Button>
            )}
        </>
    );
}
