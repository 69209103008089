import { FormWrapperProps } from '@beda.software/fhir-questionnaire';
import { Button, Form } from 'antd';

import s from './BaseQuestionnaireResponseForm.module.scss';

interface Props extends FormWrapperProps {
    readOnly?: boolean;
    setReadOnly?: (readOnly: boolean) => void;
}

export function BaseForm(props: Props) {
    const { readOnly, setReadOnly } = props;
    return (
        <Form onFinish={props.handleSubmit} layout="vertical" autoComplete="off" className={s.form}>
            <>
                <div className={s.content}> {props.items}</div>
                <div className={s.footer}>
                    {readOnly ? (
                        <Button
                            type="primary"
                            onClick={() => {
                                if (setReadOnly) {
                                    setReadOnly(false);
                                }
                            }}
                        >
                            Edit
                        </Button>
                    ) : (
                        <Button type="primary" htmlType="submit" disabled={readOnly}>
                            Submit
                        </Button>
                    )}
                </div>
            </>
        </Form>
    );
}
