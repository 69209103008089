import { Alert, Form, Modal } from 'antd';
import Button from 'antd/es/button';
import TextArea from 'antd/es/input/TextArea';

import { LabSessionEditControlProps, ReasonFormValues, useLabSessionEditControl } from './hooks';
import s from './LabSessionEditControl.module.scss';

export function LabSessionEditControl(props: LabSessionEditControlProps) {
    const { viewOnly } = props;

    const {
        handleEditModeToggle,
        saveReason,
        captureReasonForm,
        reasonText,
        showModal,
        setShowModal,
    } = useLabSessionEditControl(props);

    return (
        <>
            {viewOnly || <Alert message={`EDIT MODE: Reason: ${reasonText}`} type="warning" />}
            <Button className={s.editButton} type="primary" onClick={handleEditModeToggle}>
                {viewOnly ? 'Amend' : 'Finish editing'}
            </Button>
            <Modal
                title="Describe the reason for session editing"
                open={showModal}
                onCancel={() => setShowModal(false)}
                footer={null}
            >
                <Form<ReasonFormValues>
                    form={captureReasonForm}
                    onFinish={saveReason}
                    onFinishFailed={() => console.log('Failed')}
                    autoComplete="off"
                >
                    <Form.Item
                        name="reason"
                        rules={[
                            { required: true, message: 'Reason is required' },
                            { min: 2, message: 'Reason is too short' },
                        ]}
                        normalize={(value) => value.replace('  ', ' ')}
                    >
                        <TextArea rows={4} placeholder="Reason..." />
                    </Form.Item>
                    <Button type="primary" htmlType="submit">
                        Start editing
                    </Button>
                </Form>
            </Modal>
        </>
    );
}
