import { LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import Input from 'antd/es/input';

import { EditableCell } from '../types';
import { useNotCell } from './hooks';
import s from './NotesCell.module.scss';

export function NotesCell(props: EditableCell) {
    const { record, viewOnly } = props;
    const note = record.note || undefined;

    const { handleChange, isError, isLoading } = useNotCell(props);

    return (
        <Input
            className={s.input}
            title={note}
            key={`${record.id}-input`}
            type="string"
            onBlur={(e) => {
                handleChange(e.target.value);
            }}
            onPressEnter={() => blur()}
            disabled={viewOnly ? true : isLoading}
            defaultValue={note}
            bordered={false}
            suffix={
                isLoading ? (
                    <LoadingOutlined style={{ color: 'var(--grey)' }} />
                ) : isError ? (
                    <WarningOutlined style={{ color: 'var(--red)' }} />
                ) : undefined
            }
            status={isError ? 'error' : undefined}
        />
    );
}
