import { notification } from 'antd';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { isSuccess } from 'aidbox-react/lib/libs/remoteData';

import { oAuth2AuthCodeGetToken, setToken } from '../../services/auth';

export function OAuthCallback() {
    const [searchParams] = useSearchParams();

    const code = searchParams.get('code');
    const codeVerifier = sessionStorage.getItem('code_verifier');

    useEffect(() => {
        if (!code) {
            console.warn('No code');
            return;
        }
        if (!codeVerifier) {
            console.warn('No code_verifier');
            return;
        }

        const handleAuthCallback = async () => {
            const signinOAuth2Response = await oAuth2AuthCodeGetToken({ code, codeVerifier });
            if (isSuccess(signinOAuth2Response)) {
                const { access_token, token_type } = signinOAuth2Response.data;
                const entryPoint = localStorage.getItem('entry_point');
                setToken({ access_token, token_type });
                if (!entryPoint) {
                    window.location.reload();
                } else {
                    window.location.href = entryPoint;
                }
            } else {
                notification.error({
                    message: signinOAuth2Response.error.error_description
                        ? signinOAuth2Response.error.error_description
                        : JSON.stringify(signinOAuth2Response.error),
                });
            }
        };

        handleAuthCallback();
    }, []);

    return <p>Authorization...</p>;
}
