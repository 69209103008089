import { Specimen } from 'shared/src/contrib/aidbox';

import { service } from 'aidbox-react/lib/services/service';

export async function moveLabSessionToStep2(id: string) {
    return service({
        method: 'POST',
        url: `/LabSession/${id}/$to-step-2`,
    });
}

export async function moveLabSessionToStep3(id: string) {
    return service({
        method: 'POST',
        url: `/LabSession/${id}/$to-step-3`,
    });
}

export function extractSpecimenIdentifier(specimen: Specimen) {
    const identifiers = specimen.identifier;
    if (identifiers === undefined || identifiers.length === 0) {
        return specimen.id!;
    }
    // TODO: extract identifier by system
    if (identifiers[0].value === undefined) {
        return specimen.id!;
    }
    return identifiers[0].value;
}
