import { useContext } from 'react';

import { getUserDisplay } from '../../../utils/getUserDisplay';
import { InitialDataContext } from '../../App/hooks';

export function useUserDisplay() {
    const contextData = useContext(InitialDataContext);
    const userData = contextData?.user;
    return userData ? getUserDisplay(userData) : '-';
}
