import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';

import { setInstanceBaseURL } from 'aidbox-react/lib/services/instance';

import { patchConsole, patchWebErrorHandler } from './logging';

const spaConfig = (window as any).__SPACONFIG__;

if (spaConfig.webSentryDSN) {
    Sentry.init({
        dsn: spaConfig.webSentryDSN!,
        environment: spaConfig.environment,
        integrations: [
            new CaptureConsole({
                levels: ['error'],
            }),
        ],
    });
    Sentry.configureScope((scope) => {
        scope.setTag('environment', spaConfig.tier);
    });
}

setInstanceBaseURL(spaConfig.baseURL);

patchWebErrorHandler();
patchConsole();
