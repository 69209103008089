import { Tag } from 'antd';
import { components, OptionProps } from 'react-select';

import { FlagOption, RawFlagOptionsStepOne, RawFlagOptionsStepTwo } from './types';
import { getFlagColorConfig } from './utils';

export function CellOption<T extends FlagOption<RawFlagOptionsStepOne | RawFlagOptionsStepTwo>>(
    props: OptionProps<T>,
) {
    const { Option } = components;
    const { data } = props;
    const tagData = getFlagColorConfig(data.value);
    return (
        <Option {...props}>
            <Tag color={tagData.color} title={tagData.title} style={{ color: tagData.textColor }}>
                {data.label}
            </Tag>
        </Option>
    );
}
