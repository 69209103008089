import { parseFHIRDateTime } from 'aidbox-react';
import { LogEntry, User } from 'shared/src/contrib/aidbox';
import { formatHumanDateTime } from 'utils/date';
import { getUserDisplay } from 'utils/getUserDisplay';

export function modifyLogEntries(logEntries: LogEntry[], users: User[]) {
    return logEntries
        .sort((a, b) => {
            return parseFHIRDateTime(a.dateTime) > parseFHIRDateTime(b.dateTime) ? 1 : -1;
        })
        .map((logEntry) => {
            const user = users.find((user) => {
                return user.id === logEntry.author.id;
            });
            const userDisplay = user ? getUserDisplay(user) : logEntry.author.id;
            return {
                key: logEntry.id,
                dateTime: formatHumanDateTime(logEntry.dateTime),
                status: logEntry.text,
                author: userDisplay,
            };
        });
}
