import { ServiceManager } from 'aidbox-react';
import { notification } from 'antd';
import { getDescription } from 'components/AlertFailure';
import { useState } from 'react';

import { isFailure, isSuccess } from 'aidbox-react/lib/libs/remoteData';

import { BoxsetListWithTotal } from '../hooks';
import { createBoxset } from '../utils';

export function useBoxsetModal(
    reloadBoxsets: ServiceManager<BoxsetListWithTotal, any>['softReloadAsync'],
) {
    const [openModal, setOpenModal] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showModal = () => {
        setOpenModal(true);
    };

    const handleOk = async () => {
        setConfirmLoading(true);
        const response = await createBoxset();

        if (isSuccess(response)) {
            await reloadBoxsets();
            setOpenModal(false);
            notification.success({ message: 'New boxset created' });
        }
        if (isFailure(response)) {
            const description = getDescription(response.error);
            setOpenModal(false);
            notification.error({
                description: description,
                message: 'Error while creating boxset',
            });
        }
        setConfirmLoading(false);
        return response;
    };

    const handleCancel = () => {
        setOpenModal(false);
    };

    return { openModal, confirmLoading, showModal, handleOk, handleCancel };
}
