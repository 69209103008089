import { service } from 'aidbox-react/lib/services/service';

export interface UpdateAliquotsParams {
    tube_type: 'screw-cap' | 'PCR';
    aliquots: { PCR: number; 'screw-cap': number };
    boxset_arrangement_id: string;
    sample_number: number;
}

export async function validateAndUpdateAliquots(data: UpdateAliquotsParams) {
    return await service({
        url: 'LabSampleItem/$update-aliquots',
        method: 'POST',
        data,
    });
}
