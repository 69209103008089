import { formatError } from 'aidbox-react';
import notification from 'antd/es/notification';
import { useState } from 'react';

import { isFailure, isSuccess } from 'aidbox-react/lib/libs/remoteData';
import { service } from 'aidbox-react/lib/services/service';

import { EditableCell } from '../types';

export function useNotCell(props: EditableCell) {
    const { record, reload, sessionStatus } = props;
    const note = record.note;
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleChange = async (newNote: string) => {
        const trimmedNewNote = newNote.replace(/^\s+|\s+$/g, '');
        if ((trimmedNewNote || undefined) === note) {
            return;
        }

        setIsLoading(true);
        const patchResponse = await service({
            url: 'LabSessionItem/$update',
            method: 'PATCH',
            data: {
                LabSessionItem: {
                    resourceType: 'LabSessionItem',
                    id: record.id,
                    note: trimmedNewNote,
                },
                labSessionStatus: sessionStatus,
            },
        });
        if (isSuccess(patchResponse)) {
            await reload();
            notification.success({
                message: 'Saved',
            });
            setIsError(false);
        }
        if (isFailure(patchResponse)) {
            setIsError(true);
            notification.error({ message: formatError(patchResponse.error) });
        }
        setIsLoading(false);
    };
    return { isLoading, isError, handleChange };
}
