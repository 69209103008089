import { formatError } from 'aidbox-react';
import notification from 'antd/es/notification';
import { useDebounced } from 'hooks/debounced';
import { useCallback, useEffect, useState } from 'react';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isFailure, isSuccess } from 'aidbox-react/lib/libs/remoteData';
import { mapSuccess, sequenceMap, service } from 'aidbox-react/lib/services/service';

import { useSet } from '../../../hooks/useSet';
import { StepTwoFlagFilter } from '../FlagsCell/FlagsCellStepTwo/flags';
import { SESSION_PAGE_SIZE } from '../TableStepOne';
import { LabSessionProps, StepTwoTableData, TableStepTwoProps } from '../types';
import { extractSpecimenIdentifier, moveLabSessionToStep3 } from '../utils';
import { AliquotsStructure, RawStepTwoData } from './types';

export function useStepTwoSearch(props: TableStepTwoProps) {
    const { stepTwoTableData } = props;
    const [filteredTableData, setFilteredTableData] =
        useState<StepTwoTableData[]>(stepTwoTableData);
    const [searchText, setSearchText] = useState('');
    const [flagFilters, setFlagFilters] = useState<Array<StepTwoFlagFilter>>([]);

    const debouncedSearchText = useDebounced(searchText, 500);

    const filterData = useCallback(
        (record: StepTwoTableData) => {
            const textSearchResult = Object.keys(record).some((key) => {
                const filterableColumns = ['patient_name', 'mrn', 'specimen_identifier', 'note'];

                return (
                    filterableColumns.includes(key) &&
                    String(record[key]).toLowerCase().includes(debouncedSearchText.toLowerCase())
                );
            });

            const filterSearchResult = flagFilters.length
                ? flagFilters.some((flag) => record.flag.includes(flag))
                : true;

            return textSearchResult && filterSearchResult;
        },
        [debouncedSearchText, flagFilters],
    );

    useEffect(() => {
        let filterTable = stepTwoTableData.filter((record) => filterData(record));

        setFilteredTableData(filterTable);
    }, [debouncedSearchText, stepTwoTableData, flagFilters, filterData]);

    return {
        setSearchText,
        flagFilters,
        setFlagFilters,
        filteredTableData,
    };
}

export function useMoveStepThree(props: {
    sessionReload: LabSessionProps['sessionReload'];
    tableData: StepTwoTableData[];
}) {
    const { sessionReload, tableData } = props;
    const [erroredRows, erroredRowsActions] = useSet<string>();
    const [page, setPage] = useState(1);

    const handleChangeToStep3 = useCallback(
        async (id: string) => {
            const response = await moveLabSessionToStep3(id);
            if (isFailure(response)) {
                const diagnosticsError = response.error?.issue?.[0]?.diagnostics;
                if (diagnosticsError?.id === 'validation_flags_for_step_2') {
                    notification.warning({
                        message: 'Please setup correct flags for aliquots count',
                    });
                    erroredRowsActions.set(diagnosticsError.resources_ids);
                    const pageNumber = calculateFirstErrorRowPageNumber(
                        diagnosticsError.resources_ids,
                        tableData,
                    );
                    setPage(pageNumber);
                } else {
                    notification.warning({ message: formatError(response.error) });
                }
            }
            if (isSuccess(response)) {
                await sessionReload();
                notification.success({ message: 'Success' });
            }
        },
        [erroredRowsActions, sessionReload, tableData, setPage],
    );
    return {
        handleChangeToStep3,
        erroredRows,
        removeRecordError: erroredRowsActions.remove,
        page,
        setPage,
    };
}

function calculateFirstErrorRowPageNumber(erroredRows: Set<string>, tableData: StepTwoTableData[]) {
    const firstErrorRowId = erroredRows[0];
    const firstErrorRowIndex = tableData.findIndex((row) => row.id === firstErrorRowId);
    return Math.floor(firstErrorRowIndex / SESSION_PAGE_SIZE + 1);
}

export function useStepTwo(props: LabSessionProps) {
    const { labSession } = props;

    const [stepTwoRD, manager] = useService(async () => {
        const rawStepTwoRD = await service<RawStepTwoData[]>({
            url: `LabSession/${labSession.id}/$get-step-two-data`,
        });

        const aliquotsStructureRD = await service<AliquotsStructure>({
            url: `BoxsetArrangement/${labSession.boxsetArrangement.id}/$get-aliquots-structure`,
        });

        const responseRD = sequenceMap({ rawStepTwoRD, aliquotsStructureRD });

        return mapSuccess(responseRD, ({ rawStepTwoRD, aliquotsStructureRD }) => {
            return {
                stepTwoTableData: transformToTableStepTwoData(rawStepTwoRD),
                aliquotsStructure: aliquotsStructureRD,
            };
        });
    });

    return {
        stepTwoRD,
        stepTwoDataReload: manager.softReloadAsync,
    };
}

function transformToTableStepTwoData(rawStepTwoData: RawStepTwoData[]): StepTwoTableData[] {
    return rawStepTwoData
        .map((item) => {
            const { boxsetNumber, labSampleItem, labSessionItem, patientName, mrn, aliquots } =
                item;
            return {
                id: labSessionItem.id,
                patient_name: patientName,
                mrn: mrn,
                specimen_id: item.specimen?.id || '-',
                specimen_identifier: item.specimen ? extractSpecimenIdentifier(item.specimen) : '-',
                specimen_collected: item.specimen?.collection?.collected?.dateTime || '-',
                specimen_location: item.locationName || '-',
                labSampleItem: labSampleItem.sampleNumber,
                boxset: boxsetNumber || '-',
                flag: labSessionItem.flag?.step_two || ['notSet'],
                note: labSessionItem.note || null,
                aliquots,
            };
        })
        .sort((a, b) => (a.specimen_identifier > b.specimen_identifier ? 1 : -1));
}
