import { WithId } from 'aidbox-react';
import { Box, BoxType, Boxset, LabSampleItem } from 'shared/src/contrib/aidbox';

import { service, useService } from 'aidbox-react/lib';

export interface BoxsetMapData {
    boxset: WithId<Boxset>;
    boxItemResourcesMap: {
        [key: string]: BoxItemResources;
    };
}

export interface BoxItemResources {
    box: WithId<Box>;
    boxType: WithId<BoxType>;
    labSampleItems: WithId<LabSampleItem>[];
}

export function useBoxsetMapData(boxsetId: string) {
    const [boxsetRD] = useService<BoxsetMapData>(async () => {
        const response = await service({
            method: 'GET',
            url: `/Boxset/${boxsetId}/$get-data-map`,
        });
        return response;
    });

    return { boxsetRD };
}
