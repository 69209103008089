import { useSearchParams } from 'react-router-dom';

import { SessionEditTarget } from '../../../components/SessionEditMenu/hooks';
import { TableStepThreeContainer } from '../TableStepThree';
import { TableStepTwoContainer } from '../TableStepTwo';
import { LabSessionProps } from '../types';

export function LabSessionEditRouter(props: LabSessionProps) {
    const [searchParams] = useSearchParams();

    const target = searchParams.get('target') || SessionEditTarget.Aliquoting;

    if (target === SessionEditTarget.Aliquoting) {
        return <TableStepTwoContainer {...props} />;
    }
    return <TableStepThreeContainer {...props} />;
}
