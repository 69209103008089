import { Breadcrumb, Table } from 'antd';
import { BaseLayout } from 'components/BaseLayout';
import { BoxsetInfo, BoxsetInfoLoading } from 'components/BoxsetInfo';
import { BoxsetMap } from 'components/BoxsetMap';
import { TableLoading } from 'components/TableLoading';
import { useNavigate, useParams } from 'react-router-dom';

import { RenderRemoteData } from 'aidbox-react/lib/components/RenderRemoteData';

import s from './BoxsetDetails.module.scss';
import { useBoxsetDetails } from './hooks';

const columns = [
    {
        title: 'Update on',
        key: 'dateTime',
        dataIndex: 'dateTime',
        width: '15%',
    },
    {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        width: 'auto',
    },
    {
        title: 'Created by',
        key: 'author',
        dataIndex: 'author',
        width: '28%',
    },
];

export default function BoxsetDetails() {
    const { boxsetRD } = useBoxsetDetails();
    const navigate = useNavigate();
    const { boxsetNumber } = useParams();

    return (
        <BaseLayout showHeader={true} showMainMenu={true} className={s.wrapper}>
            <Breadcrumb>
                <Breadcrumb.Item
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    <a>Boxsets</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Boxset {boxsetNumber}</Breadcrumb.Item>
            </Breadcrumb>
            <RenderRemoteData
                remoteData={boxsetRD}
                renderLoading={() => <BoxsetDetailsLoading boxsetNumber={boxsetNumber} />}
            >
                {({ boxset, logEntries }) => {
                    return (
                        <>
                            <h2>{`Boxset ${boxset.boxsetNumber} Details`}</h2>
                            <BoxsetInfo boxset={boxset} />
                            <h3>History timeline</h3>
                            <Table
                                columns={columns}
                                dataSource={logEntries}
                                pagination={false}
                                size="small"
                            ></Table>
                            <BoxsetMap boxsetId={boxset.id}></BoxsetMap>
                        </>
                    );
                }}
            </RenderRemoteData>
        </BaseLayout>
    );
}

export function BoxsetDetailsLoading(props: { boxsetNumber?: string }) {
    const { boxsetNumber = '' } = props;
    return (
        <>
            <h2>{`Boxset ${boxsetNumber} Details`}</h2>
            <BoxsetInfoLoading />
            <h3>History timeline</h3>
            <TableLoading baseColumns={columns} pagination={false} />
        </>
    );
}
