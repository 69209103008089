import { useState } from 'react';
import { LabSession } from 'shared/src/contrib/aidbox';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isFailure } from 'aidbox-react/lib/libs/remoteData';
import { mapSuccess, service } from 'aidbox-react/lib/services/service';

import { formatLabSessionsList } from './utils';

export interface SessionListWithTotal {
    labSessionItems: LabSessionTableItem[];
    total: number;
}

export interface LabSessionTableItem {
    id: string;
    created_date: string;
    status: LabSession['status'];
    max_collected_date: string;
    min_collected_date: string;
    specimens_count: number;
}

export function useLabSession() {
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalSessions, setTotalSessions] = useState(0);

    const [labSessionsRD, labSessionsManager] = useService(async () => {
        const response = await service<SessionListWithTotal>({
            method: 'GET',
            url: `/LabSession/$get-list?_count=${pageSize}&_page=${pageNumber}`,
        });
        if (isFailure(response)) {
            console.error({ message: JSON.stringify(response.error) });
        }

        return mapSuccess(response, ({ labSessionItems, total }) => {
            const labSessionTableItems = formatLabSessionsList(labSessionItems);
            setTotalSessions(total);
            const data: SessionListWithTotal = {
                labSessionItems: labSessionTableItems,
                total,
            };
            return data;
        });
    }, [pageNumber, pageSize]);
    return {
        labSessionsRD,
        manager: labSessionsManager.softReloadAsync,
        setPageNumber,
        pageNumber,
        setPageSize,
        pageSize,
        totalSessions,
    };
}
