import { Questionnaire } from '@beda.software/fhir-questionnaire/contrib/aidbox';
import { failure, formatError, isFailure } from 'aidbox-react';
import { notification } from 'antd';
import { QuestionnaireResponse } from 'fhir/r4b';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { serviceProvider } from 'services/auth';

import { sequenceMap } from 'aidbox-react/lib';
import { useService } from 'aidbox-react/lib/hooks/service';
import { getFHIRResource, makeReference } from 'aidbox-react/lib/services/fhir';
export function useSurvey() {
    const { qr_id } = useParams();
    const [readOnly, setReadOnly] = useState(true);

    const [resourcesRD, resourcesManager] = useService(async () => {
        let questionnaire_id: string | undefined;
        const questionnaireResponse = await serviceProvider.getFHIRResource<QuestionnaireResponse>({
            reference: `QuestionnaireResponse/${qr_id}`,
        });
        if (isFailure(questionnaireResponse)) {
            notification.error({ message: formatError(questionnaireResponse.error) });
            return questionnaireResponse;
        }
        questionnaire_id = questionnaireResponse.data.questionnaire;
        if (!questionnaire_id) {
            return failure<any>('Unknown questionnaire id');
        }

        const questionnaire = await getFHIRResource<Questionnaire>(
            makeReference('Questionnaire', questionnaire_id),
        );

        return sequenceMap({ questionnaireResponse, questionnaire });
    });

    const onSuccess = async () => {
        notification.success({ message: 'Survey saved successfully!' });
        resourcesManager.softReloadAsync();
        setReadOnly(true);
    };

    return { resourcesRD, resourcesManager, readOnly, setReadOnly, onSuccess };
}
