import { Modal } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { BoxsetMap } from 'components/BoxsetMap';
import { parseISO } from 'date-fns';
import { useState } from 'react';

import { DatePickerFNS } from '../../../components/DatePickerFNS';
import { US_DATE_TIME_FORMAT } from '../../../utils/date';
import { StepThreeTableData } from '../types';
import s from './TableStepThree.module.scss';
import { UpdateFrozenDateTimeData } from './types';

export function getStepThreeColumns(
    updateFrozenDateTime: (data: UpdateFrozenDateTimeData) => Promise<void>,
    viewOnly: boolean,
): ColumnsType<StepThreeTableData> {
    return [
        {
            title: 'Boxset',
            key: 'boxsetNumber',
            dataIndex: 'boxsetNumber',
            width: '50px',
        },
        {
            title: 'Sample start',
            key: 'sampleStart',
            dataIndex: 'sampleStart',
        },
        {
            title: 'Sample end',
            key: 'sampleEnd',
            dataIndex: 'sampleEnd',
        },
        {
            title: 'Frozen',
            key: 'frozenDateTime',
            dataIndex: 'frozenDateTime',
            render: (_text, record) => (
                <DatePickerFNS
                    className={s.dateTimePicker}
                    onChange={(e) => {
                        if (e) {
                            updateFrozenDateTime({
                                boxsetId: record.boxsetId,
                                frozenDateTime: e.toISOString(),
                            });
                        }
                    }}
                    defaultValue={
                        record.frozenDateTime?.length ? parseISO(record.frozenDateTime) : undefined
                    }
                    bordered={false}
                    suffixIcon={false}
                    placeholder="Select date and time"
                    format={US_DATE_TIME_FORMAT}
                    allowClear={false}
                    showTime
                    disabled={viewOnly}
                />
            ),
            width: '250px',
        },
        {
            title: 'Status',
            key: 'boxsetEmptySamplesCount',
            dataIndex: 'boxsetEmptySamplesCount',
            render: (_, record) => (record['boxsetEmptySamplesCount'] > 0 ? 'In progress' : 'Used'),
        },
        {
            title: 'Box Map',
            render: (_, record) => <BoxsetView record={record} />,
        },
    ];
}

function BoxsetView(props: { record: StepThreeTableData }) {
    const { record } = props;
    const [boxsetMapOpen, setBoxsetMapOpen] = useState(false);
    //
    return (
        <>
            <a
                onClick={() => {
                    setBoxsetMapOpen(true);
                }}
            >
                View
            </a>
            <Modal
                open={boxsetMapOpen}
                onCancel={() => {
                    setBoxsetMapOpen(false);
                }}
                footer={null}
                width={'fit-content'}
            >
                <BoxsetMap
                    boxsetId={record.boxsetId}
                    activeRange={{ start: record.sampleStart, end: record.sampleEnd }}
                ></BoxsetMap>
            </Modal>
        </>
    );
}
