import { ConfigProvider as AntConfigProvider } from 'antd';
import Button from 'antd/es/button';
import Search from 'antd/es/input/Search';
import Table from 'antd/es/table';
import { useMemo, useState } from 'react';

import { RenderRemoteData } from 'aidbox-react/lib/components/RenderRemoteData';

import { PrintedComponent } from '../../../components/PrintedComponent';
import { formatHumanDateTime } from '../../../utils/date';
import { StepTwoFlagFilter } from '../FlagsCell/FlagsCellStepTwo/flags';
import { LabSessionEditControl } from '../LabSessionEditControl';
import { PrintedSpecimenTable } from '../PrintedSpecimenTable';
import { SpecimentIdColumntTitle } from '../SpecimentIdColumntTitle';
import { SESSION_PAGE_SIZE } from '../TableStepOne';
import { LabSessionProps, StepTwoTableData, TableStepTwoProps } from '../types';
import { getStepTwoColumns, getStepTwoPrintedColumns } from './columns';
import { useMoveStepThree, useStepTwo, useStepTwoSearch } from './hooks';
import s from './TableStepTwo.module.scss';

export function TableStepTwoContainer(props: LabSessionProps) {
    const { labSession, sessionReload } = props;
    const { stepTwoRD, stepTwoDataReload } = useStepTwo(props);
    return (
        <RenderRemoteData remoteData={stepTwoRD}>
            {({ stepTwoTableData, aliquotsStructure }) => {
                return (
                    <TableStepTwo
                        stepTwoTableData={stepTwoTableData}
                        stepTwoDataReload={stepTwoDataReload}
                        sessionReload={sessionReload}
                        labSession={labSession}
                        aliquotsStructure={aliquotsStructure}
                    />
                );
            }}
        </RenderRemoteData>
    );
}

export function TableStepTwo(props: TableStepTwoProps) {
    const { stepTwoTableData, stepTwoDataReload, aliquotsStructure, labSession } = props;
    const { setSearchText, flagFilters, setFlagFilters, filteredTableData } =
        useStepTwoSearch(props);

    const [viewOnly, setViewOnly] = useState(labSession.status !== 'aliquoting');
    const { handleChangeToStep3, erroredRows, removeRecordError, page, setPage } = useMoveStepThree(
        {
            sessionReload: props.sessionReload,
            tableData: filteredTableData,
        },
    );

    const editableTableColumns = useMemo(
        () =>
            getStepTwoColumns(
                stepTwoDataReload,
                flagFilters,
                aliquotsStructure,
                removeRecordError,
                labSession.status,
                labSession.boxsetArrangement.id,
                viewOnly,
            ),
        [
            aliquotsStructure,
            flagFilters,
            labSession.boxsetArrangement.id,
            labSession.status,
            removeRecordError,
            stepTwoDataReload,
            viewOnly,
        ],
    );
    const printedTableColumns = getStepTwoPrintedColumns(
        aliquotsStructure,
        stepTwoDataReload,
        removeRecordError,
        labSession.boxsetArrangement.id,
    );

    return (
        <>
            <div className={s.actionsBlock}>
                <Search
                    placeholder={`Name, MRN, ${SpecimentIdColumntTitle()} or Note`}
                    title={`Search by Name, MRN, ${SpecimentIdColumntTitle()} or Note`}
                    enterButton="Search"
                    allowClear
                    onChange={(e) => {
                        setSearchText(e.target.value);
                    }}
                    onSearch={setSearchText}
                    className={s.searchInput}
                />
                <div className={s.actionButtons}>
                    {labSession.status === 'aliquoting' && (
                        <>
                            <PrintedComponent printButtonTitle={'Print'}>
                                <PrintedSpecimenTable<StepTwoTableData>
                                    labSessionDate={formatHumanDateTime(labSession.createdDateTime)}
                                    dataSource={stepTwoTableData}
                                    columns={printedTableColumns}
                                />
                            </PrintedComponent>
                            <Button
                                type="primary"
                                onClick={() => {
                                    handleChangeToStep3(labSession.id);
                                }}
                            >
                                Move to Step 3
                            </Button>
                        </>
                    )}

                    {labSession.status === 'completed' && (
                        <LabSessionEditControl
                            labSession={labSession}
                            viewOnly={viewOnly}
                            setViewOnly={setViewOnly}
                        />
                    )}
                </div>
            </div>
            <AntConfigProvider
                theme={{
                    components: {
                        Select: { borderRadius: 0, controlHeight: 40, colorBorder: 'transparent' },
                    },
                }}
            >
                <Table
                    className={s.table}
                    columns={editableTableColumns}
                    dataSource={filteredTableData}
                    onChange={(_pagination, filters) => {
                        const flagFilters = (
                            filters.flag ? filters.flag : []
                        ) as StepTwoFlagFilter[];
                        setFlagFilters(flagFilters);
                    }}
                    bordered
                    rowKey={(record) => record.id}
                    rowClassName={(record) => (erroredRows.has(record.id) ? s.errored : '')}
                    pagination={{
                        pageSize: SESSION_PAGE_SIZE,
                        showSizeChanger: false,
                        current: page,
                        onChange: (page) => {
                            setPage(page);
                        },
                    }}
                />
            </AntConfigProvider>
        </>
    );
}
