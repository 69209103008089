import Button from 'antd/es/button';
import { ReactNode, useRef } from 'react';
import ReactToPrint from 'react-to-print';

import s from './PrintedComponent.module.scss';

export interface PrintedComponentProps {
    printButtonTitle: string;
    children: ReactNode | ReactNode[];
}

export function PrintedComponent(props: PrintedComponentProps) {
    const { children, printButtonTitle } = props;
    const componentRef = useRef(null);
    return (
        <div>
            <ReactToPrint
                trigger={() => <Button>{printButtonTitle}</Button>}
                content={() => componentRef.current}
                pageStyle="@page { margin: 10mm, size: landscape }"
            />
            <div style={{ display: 'none' }}>
                <div ref={componentRef}>
                    <BlackDotsLayout>{children}</BlackDotsLayout>
                </div>
            </div>
        </div>
    );
}

export function BlackDotsLayout(props: { children: ReactNode | ReactNode[] }) {
    return (
        <table className={s.table}>
            <thead>
                <tr>
                    <th className={s.printedLayoutHeader}>
                        <span>&#11035;</span>
                        <span>&#11035;</span>
                    </th>
                </tr>
            </thead>
            <tfoot>
                <tr>
                    <td>
                        <div>
                            <span>&#11035;</span>
                        </div>
                    </td>
                </tr>
            </tfoot>
            <tbody>
                <tr>
                    <td className={s.main}>{props.children}</td>
                </tr>
            </tbody>
        </table>
    );
}
