import Table, { ColumnsType } from 'antd/es/table';
import { TableSkeletonItem } from 'components/TableSkeletonItem';

import s from './TableLoading.module.scss';

export function TableLoading(props: {
    pageNumber?: number;
    pageSize?: number;
    total?: number;
    baseColumns: ColumnsType<any>;
    pagination?: boolean;
}) {
    const { pageNumber, pageSize = 5, total, baseColumns, pagination = true } = props;

    const columnsTableLoading = baseColumns.map((column) => {
        const loadingColumn = { ...column };
        delete loadingColumn.render;
        return loadingColumn;
    });

    const dataItem = {};
    columnsTableLoading.forEach((columnItem) => {
        dataItem[`${columnItem.key}`] = <TableSkeletonItem />;
    });

    const data = Array.from({ length: pageSize }).map((_element, index) => ({
        ...dataItem,
        key: (index + 1).toString(),
    }));

    return (
        <Table
            columns={columnsTableLoading}
            dataSource={data}
            loading
            bordered
            size="middle"
            rowKey={(record) => record.key}
            className={s.tableClass}
            pagination={
                pagination
                    ? {
                          current: pageNumber,
                          pageSize,
                          total,
                          pageSizeOptions: [5, 10, 20, 50],
                          showSizeChanger: true,
                      }
                    : false
            }
        />
    );
}
