import { Questionnaire } from '@beda.software/fhir-questionnaire/contrib/aidbox';
import * as yup from 'yup';

export function questionnaireToValidationSchema(questionnaire: Questionnaire) {
    const validationSchema: Record<string, yup.AnySchema> = {};
    if (questionnaire.item === undefined)
        return yup.object(validationSchema) as yup.AnyObjectSchema;
    questionnaire.item.forEach((item) => {
        if (item.type === 'string') {
            let stringSchema = yup.string();
            if (item.required) stringSchema = stringSchema.required();
            if (item.maxLength && item.maxLength > 0)
                stringSchema = stringSchema.max(item.maxLength);
            validationSchema[item.linkId] = createSchemaArray(
                yup.object({ string: stringSchema }),
            ).required() as unknown as yup.AnySchema;
        } else if (item.type === 'integer') {
            let numberSchema = yup.number();
            if (item.required) numberSchema = numberSchema.required();
            if (item.itemControl?.coding?.[0].code === 'percentage-slider') {
                validationSchema[item.linkId] = createSchemaArray(
                    yup.object({ decimal: numberSchema }),
                ).required() as unknown as yup.AnySchema;
            } else {
                validationSchema[item.linkId] = createSchemaArray(
                    yup.object({ integer: numberSchema }),
                ).required() as unknown as yup.AnySchema;
            }
        } else if (item.type === 'choice') {
            let choiceSchema = yup.string().required();
            if (item.required) choiceSchema = choiceSchema.required();
            validationSchema[item.linkId] = createSchemaArray(
                yup.object({
                    Coding: yup.object({ code: choiceSchema }),
                }),
            ).required() as unknown as yup.AnySchema;
        } else if (item.type === 'dateTime') {
            let dateSchema = yup.date().required();
            if (item.required) dateSchema = dateSchema.required();
            validationSchema[item.linkId] = createSchemaArray(
                yup.object({ dateTime: dateSchema }),
            ).required() as unknown as yup.AnySchema;
        } else {
            (validationSchema[item.linkId] as any) = item.required
                ? yup.mixed().required()
                : yup.mixed().nullable();
        }
    });

    return yup.object(validationSchema).required() as yup.AnyObjectSchema;
}

function createSchemaArray(value: yup.ObjectSchema<any>) {
    return yup.array().of(yup.object({ value }));
}
