import { CheckOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { Dispatch, SetStateAction } from 'react';

import { ModalRecord } from '../hooks';
import { getModalTitle } from '../utils';
import s from './ChangeStatusModal.module.scss';

interface ChangeStatusData {
    modalData: ModalRecord | null;
    open: boolean;
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    loading: boolean;
    onStatusChange: () => void;
}

export function ChangeStatusModal(props: ChangeStatusData) {
    const { modalData, open, onStatusChange, setOpenModal, loading } = props;
    return (
        <>
            {modalData && (
                <Modal
                    width={416}
                    open={open}
                    onCancel={() => setOpenModal(false)}
                    closable={false}
                    footer={[
                        <Button
                            key="CancelBtn"
                            onClick={() => setOpenModal(false)}
                            disabled={loading}
                        >
                            Cancel
                        </Button>,
                        <Button
                            type="primary"
                            key="OkBtn"
                            onClick={onStatusChange}
                            disabled={loading}
                            icon={loading ? <LoadingOutlined /> : <CheckOutlined />}
                        >
                            Confirm
                        </Button>,
                    ]}
                >
                    <div className={s.modal}>
                        <div className={s.modalHeader}>
                            <ExclamationCircleOutlined className={s.modalIcon} />
                            <span className={s.modalTitle}>
                                {getModalTitle(modalData.newStatus, modalData.record.boxsetNumber)}
                            </span>
                        </div>
                        <span className={s.modalSubTitle}>
                            You will not be able to undo this action
                        </span>
                    </div>
                </Modal>
            )}
        </>
    );
}
