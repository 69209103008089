import { Button, Radio, Space, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TableLoading } from 'components/TableLoading';
import { formatDistance } from 'date-fns';
import { Link } from 'react-router-dom';
import { formatHumanDateTime } from 'utils/date';

import { RenderRemoteData } from 'aidbox-react/lib/components/RenderRemoteData';

import { BaseLayout } from '../../components/BaseLayout';
import { OrderItem, useLabOrders } from './hooks';
import s from './OrderList.module.scss';
import { SurveyAssignControl } from './SurveyAssigneeModal';
import { humanReadableTaskStatus, taskStatusColorValues } from './utils';

const filterOptions = [
    { label: 'Last 24 hours', value: true, title: 'Show survey data for the last 24 hours' },
    { label: 'All data', value: false, title: 'Show survey data for all time' },
];

const orderOptions = [
    {
        label: 'Most recent',
        value: 'default',
        title: 'Show most recent survey data first',
    },
    {
        label: 'Ordering Provider',
        value: 'ordering-provider',
        title: 'Sort by Ordering Provider column',
    },
];

export function OrderList() {
    const {
        ordersRd,
        pageNumber,
        pageSize,
        setPageNumber,
        setPageSize,
        totalOrders,
        setFilter,
        filter,
        order,
        setOrder,
        assignOnTask,
    } = useLabOrders();

    return (
        <BaseLayout showHeader={true} showMainMenu={true} className={s.wrapper}>
            <div className={s.header}>
                <h2>Surveys</h2>
                <div className={s.filtersGroup}>
                    <div className={s.filerItem}>
                        <h4>Order by:</h4>
                        <Radio.Group
                            options={orderOptions}
                            onChange={(e) => setOrder(e.target.value)}
                            value={order}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </div>
                    <div className={s.filerItem}>
                        <h4>Filter:</h4>
                        <Radio.Group
                            options={filterOptions}
                            onChange={(e) => setFilter(e.target.value)}
                            value={filter}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </div>
                </div>
            </div>
            <RenderRemoteData
                remoteData={ordersRd}
                renderLoading={() => (
                    <TableLoading
                        pageNumber={pageNumber}
                        pageSize={pageSize}
                        total={totalOrders}
                        baseColumns={getOrdersColumns(() => {})}
                    />
                )}
            >
                {({ orderItems, total }) => {
                    const paginationConfig = {
                        current: pageNumber,
                        pageSize: pageSize,
                        total: total,
                        pageSizeOptions: [10, 20, 50],
                        showSizeChanger: true,
                    };
                    return (
                        <Table
                            className={s.table}
                            scroll={{ x: 800 }}
                            columns={getOrdersColumns(assignOnTask)}
                            dataSource={orderItems}
                            bordered
                            size="middle"
                            pagination={{
                                onChange: (newPage, newPageSize) => {
                                    setPageNumber(newPage);
                                    setPageSize(newPageSize);
                                },
                                ...paginationConfig,
                            }}
                            rowKey={(record) => record.id}
                        />
                    );
                }}
            </RenderRemoteData>
        </BaseLayout>
    );
}

export function getOrdersColumns(
    assignOnTask: (task_id: string, userId?: string | null) => void,
): ColumnsType<OrderItem> {
    const columns: ColumnsType<OrderItem> = [
        {
            title: 'MRN',
            dataIndex: 'mrn',
            key: 'mrn',
        },
        {
            title: 'Ordering Provider',
            dataIndex: 'requester',
            key: 'requester',
        },
        {
            title: 'Ordered',
            dataIndex: 'order_date_time',
            key: 'order_date_time',
            render: (order_date_time) => {
                return (
                    <>
                        {formatHumanDateTime(order_date_time)} -{' '}
                        {formatDistance(new Date(order_date_time), new Date())} ago
                    </>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            render: (status) => {
                return (
                    <Tag color={taskStatusColorValues[status].color}>
                        {humanReadableTaskStatus[status]}
                    </Tag>
                );
            },
        },
        {
            title: 'Assignee',
            key: 'coordinator_id',
            width: '15%',
            render: (id, record) => {
                if (!record.coordinator_id && !record.questionnaire_response_id) {
                    return (
                        <Space direction="horizontal">
                            <SurveyAssignControl
                                onSuccess={(userId) => assignOnTask(record.id, userId)}
                                currentAssignedUserId={record.coordinator_id}
                            />
                            <Button
                                style={{ paddingLeft: 0 }}
                                type="link"
                                onClick={() => {
                                    assignOnTask(record.id);
                                }}
                            >
                                Self
                            </Button>
                        </Space>
                    );
                }
                return (
                    <Space direction="horizontal">
                        <div>{record.coordinator_display || record.coordinator_id}</div>
                        {!record.questionnaire_response_id && (
                            <SurveyAssignControl
                                onSuccess={(userId) => assignOnTask(record.id, userId)}
                                currentAssignedUserId={record.coordinator_id}
                            />
                        )}
                    </Space>
                );
            },
        },
        {
            title: 'Response',
            dataIndex: 'id',
            key: 'id',
            width: '20%',
            render: (id, record) => {
                const { questionnaire_response_id } = record;
                if (!questionnaire_response_id) {
                    return (
                        <>
                            <Button type="link">
                                <Link to={`/surveys/${id}/new/`}>Add</Link>
                            </Button>
                            <Button type="link">
                                <Link to={`/surveys/${id}/rejected-survey/`}>Report Issue</Link>
                            </Button>
                        </>
                    );
                }
                return (
                    <>
                        <Button type="link">
                            <Link to={`/surveys/${id}/${questionnaire_response_id}`}>View</Link>
                        </Button>
                    </>
                );
            },
        },
    ];
    return columns;
}
